import { ActionTree } from 'vuex';
import { ZRDocumentState } from './types';
import { RootState } from '../../types';
import { defaultBackendZRDocument } from '@/shared/backend/zrDocument';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import zrDocument, { Value, ZRDocument } from '@/shared/model/zrDocument';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import { FacetSearchData, SearchData } from '@/shared/model/smallPayloadModels/searchData';
import DownloadUtils from '@/shared/utils/DownloadUtils';

const logger = new Logger('actions.zrDocuments');
export const actions: ActionTree<ZRDocumentState, RootState> = {
  getZRDocuments({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any; searchData?: any }) {
    commit('setZRDocumentsIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getZRDocumentsSearchParams;
    let searchData = payload?.searchData ?? {};
    return defaultBackendZRDocument
      .getZRDocuments(searchParams, searchData)
      .then((response: AxiosResponse<Value>) => {
        let suppliersInfo = rootGetters['auth/supplierTooltipInfo'];
        // console.log('suppliersInfo :>> ', suppliersInfo);
        //actionGetZRDocuments
        console.log('-----------------------setZRDocuments-------------response.data------------------------  ===>>>   ', response.data);
        // console.log('response.data :>> ', response.data);
        if (suppliersInfo) { // ONLY_DEV (GSP-117) for now loading tooltip only for dev
          response.data.value = response.data.value.map((item: any) =>
            Object.assign(
              item,
              suppliersInfo.find((y: any) => y.zrNummer == item.zrNummer_Lieferant)
            )
          );

        }

        // console.log('response.data after :>> ', response.data);
        console.log('-----------------------setZRDocuments-------------response.data------------------------  ===>>>   ', response.data);
        commit('setZRDocuments', response.data);
        commit('setZRDocumentsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setZRDocumentsIsLoading', false);
        module_utils.error('getZRDocuments error', commit, e, logger);
        throw e;
      });
  },
  getZRDocumentsFacetSearch({ commit, dispatch }, payload: FacetSearchData) {
    return defaultBackendZRDocument
      .getZRDocumentsFacetSearch(payload)
      .then((response: AxiosResponse<any>) => {
        logger.log('getZRDocumentsFacetSearch response :>> ', response);
        const result = response.data.$values ? response.data.$values : response.data;
        // commit('setZrDocumentsRechnungsnummers', result);
        return result;
      })
      .catch((e: any) => {
        module_utils.error('getZRDocumentsFacetSearch error', commit, e, logger);
        throw e;
      });
  },
  getZrDocumentsRechnungsnummers({ commit, dispatch }, payload?: SearchData) {
    return defaultBackendZRDocument
      .getZRDocumentRechnungsnummer(payload)
      .then((response: AxiosResponse<any>) => {
        logger.log('getZrDocumentsRechnungsnummers response :>> ', response);
        const result = response.data.$values ? response.data.$values : response.data;
        commit('setZrDocumentsRechnungsnummers', result);
        return result;
      })
      .catch((e: any) => {
        module_utils.error('getZrDocumentsRechnungsnummers error', commit, e, logger);
        throw e;
      });
  },
  getZrDocumentsStatuses({ commit, dispatch }, payload?: SearchData) {
    return defaultBackendZRDocument
      .getZrDocumentsStatuses(payload)
      .then((response: AxiosResponse<any>) => {
        logger.log('getZrDocumentsStatuses response :>> ', response);
        const result = response.data.$values ? response.data.$values : response.data;
        commit('setZrDocumentsStatuses', result);
        return result;
      })
      .catch((e: any) => {
        module_utils.error('getZrDocumentsStatuses error', commit, e, logger);
        throw e;
      });
  },
  getZrDocumentsMembers({ commit, dispatch }, payload?: SearchData) {
    return defaultBackendZRDocument
      .getZrDocumentsMembers(payload)
      .then((response: AxiosResponse<any>) => {
        logger.log('getZrDocumentsMembers response :>> ', response);
        const result = response.data.$values ? response.data.$values : response.data;
        commit('setZrDocumentsMembers', result);
        return result;
      })
      .catch((e: any) => {
        module_utils.error('getZrDocumentsMembers error', commit, e, logger);
        throw e;
      });
  },
  getZrDocumentsSuppliers({ commit, dispatch }, payload?: SearchData) {
    return defaultBackendZRDocument
      .getZrDocumentsSuppliers(payload)
      .then((response: AxiosResponse<any>) => {
        logger.log('getZrDocumentsSuppliers response :>> ', response);
        const result = response.data.$values ? response.data.$values : response.data;
        // commit('setZrDocumentsSuppliers', result);
        return result;
      })
      .catch((e: any) => {
        module_utils.error('getZrDocumentsSuppliers error', commit, e, logger);
        throw e;
      });
  },

  getZrDocumentsExcelReport({ commit, dispatch, getters, rootGetters }, payload?: any) {
    let searchParams = payload?.searchParams ?? getters.getZRDocumentsSearchParams;
    commit('setZrDocumentExcelIsLoading', true);
    return defaultBackendZRDocument
      .getZrDocumentsExcelReport(payload, searchParams)
      .then((response: any) => {
        if (response.headers['content-disposition']) {
          const fileName = DownloadUtils.getFileName(response.headers['content-disposition']);
          const type = response.headers['content-type'];
          console.log('type :>> ', type);
          const blob = new Blob([response.data], { type: type });
          console.log('blob :>> ', blob);
          const url = DownloadUtils.createDownloadLink(blob, fileName);
          console.log('url :>> ', url);
          window.URL.revokeObjectURL(url);
        }
        commit('setZrDocumentExcelIsLoading', false);

        console.log('response.data :>> ', response.data);
        return response.data;
      })
      .catch((e: any) => {
        commit('setZrDocumentExcelIsLoading', false);
        module_utils.error('getZrDocumentsExcelReport error', commit, e, logger);
        throw e;
      });
  },
  getZrDocumentsPdfReport({ commit, dispatch, getters, rootGetters }, payload?: any) {
    let searchParams = payload?.searchParams ?? getters.getZRDocumentsSearchParams;
    commit('setZrDocumentPdfIsLoading', true);
    return defaultBackendZRDocument
      .getZrDocumentsPdfReport(payload, searchParams)
      .then((response: any) => {
        if (response.headers['content-disposition']) {
          const fileName = DownloadUtils.getFileName(response.headers['content-disposition']);
          const type = response.headers['content-type'];
          console.log('type :>> ', type);
          const blob = new Blob([response.data], { type: type });
          console.log('blob :>> ', blob);
          const url = DownloadUtils.createDownloadLink(blob, fileName);
          console.log('url :>> ', url);
          window.URL.revokeObjectURL(url);
        }
        console.log('response.data :>> ', response.data);
        commit('setZrDocumentPdfIsLoading', false);

        return response.data;
      })
      .catch((e: any) => {
        commit('setZrDocumentPdfIsLoading', false);
        module_utils.error('getZrDocumentsPdfReport error', commit, e, logger);
        // throw e;
      });
  },

  getAzureFile({ commit, dispatch, getters, rootGetters }, payload?: any) {
    return defaultBackendZRDocument
      .getAzureFile(payload)
      .then((response: any) => {
        if (response.headers['content-disposition']) {
          const fileName = DownloadUtils.getFileName(response.headers['content-disposition']);
          const type = response.headers['content-type'];
          console.log('type :>> ', type);
          const blob = new Blob([response.data], { type: type });
          console.log('blob :>> ', blob);
          // const url = DownloadUtils.createDownloadLink(blob, fileName);
          // console.log('url :>> ', url);
          // window.URL.revokeObjectURL(url);
          return blob;
        }
        console.log('response.data :>> ', response.data);
        return response.data;
      })
      .catch((e: any) => {
        // TODO: (GSP-076) temp solution until Azure Storage domain will be set up, then delete and uncomment `throw`
        // commit('setZrDocumentsSuppliers', result);
        logger.error(e);
        return e;
        // module_utils.error('error', commit, e, logger);
        // throw e;
      });
  },
  getZrDocumentsPdfReportView({ commit, dispatch, getters, rootGetters }, payload?: any) {
    return defaultBackendZRDocument
      .getZrDocumentsPdfReportView(payload)
      .then((response: any) => {
        if (response.headers['content-disposition']) {
          const fileName = DownloadUtils.getFileName(response.headers['content-disposition']);
          const type = response.headers['content-type'];
          console.log('type :>> ', type);
          const blob = new Blob([response.data], { type: type });
          console.log('blob :>> ', blob);
          const url = DownloadUtils.createDownloadLink(blob, fileName);
          console.log('url :>> ', url);
          window.URL.revokeObjectURL(url);
        }
        console.log('response.data :>> ', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('getZrDocumentsPdfReportView error', commit, e, logger);
        throw e;
      });
  },
  getZRDocument({ commit, dispatch, rootGetters }, id: string) {
    let suppliersInfo = rootGetters['auth/supplierTooltipInfo'];
    return defaultBackendZRDocument
      .getZRDocument(id)
      .then((response: AxiosResponse<ZRDocument>) => {

        if (suppliersInfo) { // ONLY_DEV (GSP-117) for now loading tooltip only for dev
          response.data = Object.assign(
            response.data,
            suppliersInfo.find((y: any) => y.zrNummer == response.data.zrNummer_Lieferant)
          )
        }

        commit('setZRDocument', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('getZRDocument error', commit, e, logger);
        throw e;
      });
  },
  updateZRDocument({ commit, dispatch }, file: ZRDocument) {
    return defaultBackendZRDocument
      .updateZRDocument(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ZRDocument;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.zrDocument_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('updateZRDocument error', commit, e, logger);
        throw e;
      });
  },
  updateZRDocumentStatus({ commit, dispatch }, file: any) {
    commit('setZRDocumentsIsLoading', true);
    return defaultBackendZRDocument
      .updateZRDocumentStatus(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ZRDocument;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.zrDocument_status_update`), commit);
          commit('setZrDocumentStatusInList', response.data.result);
          commit('setZRDocument', response.data.result);
          commit('setZRDocumentsIsLoading', false);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('updateZRDocumentStatus error', commit, e, logger);
        commit('setZRDocumentsIsLoading', false);
        throw e;
      });
  },
  updateZRDocumentCommission({ commit, dispatch }, file: any) {
    return defaultBackendZRDocument
      .updateZRDocumentCommission(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ZRDocument;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.zrDocument_commission_update`), commit);
          commit('setZRDocument', response.data.result);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('updateZRDocumentCommission error', commit, e, logger);
        throw e;
      });
  },
  deleteZRDocument({ commit, dispatch }, id: string) {
    return defaultBackendZRDocument
      .deleteZRDocument(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ZRDocument;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('deleteZRDocument error', commit, e, logger);
        throw e;
      });
  },

  updateSearchData({ commit, dispatch }, data: any) {
    commit('setSearchData', data);
  },

  resetSearchFormData({ commit, dispatch }, data: any) {
    commit('resetSearchFormData', data);
  },

  updateInitialLocalStorageSearchData({ commit, dispatch }, data: any) {
    commit('setInitialLocalStorageSearchData', data);
  },
  updateZrDocumentsSuppliers({ commit, dispatch }, data: any) {
    commit('setZrDocumentsSuppliers', data);
  },
};
