export interface UserData {
    id: string; // System.Guid
    email: string; // string?
    searchData: string; // string?
    invoiceSearchData: string; // string?
    changeVoucherSearchData: string; // string?
    wochenabschluesseSearchData: string; // string?
    lastUpdatedAt: string; // string?
}

export interface Value {
  value: UserData[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<UserData>): any {
  return {
    Id: data?.id || undefined,
    Email: data?.email || '',
    SearchData: data?.searchData || '',
    InvoiceSearchData: data?.invoiceSearchData || '',
    ChangeVoucherSearchData: data?.changeVoucherSearchData || '',
    WochenabschluesseSearchData: data?.wochenabschluesseSearchData || '',
  };
}

function parse(data?: Partial<UserData>): UserData {
  return {
    id: data?.id || '',
    email: data?.email || '',
    searchData: data?.searchData || '',
    invoiceSearchData: data?.invoiceSearchData || '',
    changeVoucherSearchData: data?.changeVoucherSearchData || '',
    wochenabschluesseSearchData: data?.wochenabschluesseSearchData || '',
    lastUpdatedAt: data?.lastUpdatedAt || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
