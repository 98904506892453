export interface ChangeVoucher {
  id: string; // ulong
  nd_id: string; // string
  process_Id: string; // string?
  aa_type_id: number; // byte
  reason?: number; // byte // was `change_reason`
  status: number; // byte
  int_status: number; // byte
  wv_date: string; // System.DateTime
  reference_id: string; // string?
  commission_no: string; // string?
  original: number; // byte?
  ext_document_no: string; // string?
  ext_document_date: string; // System.DateTime
  processing_date: string; // System.DateTime?
  due_date: string; // System.DateTime?
  net_amount: number | undefined; // int
  gross_amount: number | undefined; // int
  currency: string; // string?
  tax_rate: number | undefined; // sbyte?
  tax_amount: number; // int
  cash_discount_rate: number; // sbyte
  cash_discount_period: number; // int
  discount_rate: number; // sbyte
  discount_amount: number; // int
  total_discount_rate: number; // sbyte
  zr_number_member: string; // string
  name_mitglied: string; // string
  zr_number_supplier: string; // string
  rebooking_zr_no: string; // string
  name_lieferant: string; // string?
  total_discount_rate_info: string; // string?
  attachment: string; // string
  end_of_week_date: string; // System.DateTime?
  updated_by: string; // string?
  updated: string; // System.DateTime?
  created_by: string; // string?
  created: string; // System.DateTime?

  discountable: boolean;
  cv_skontosatz: number | null; // uint?
  cv_belegnr: string; // string?
  description: string; // string?

  aae_typ_id_frontend: string; // pure frontend

  dms_nr_invoice: string; // from backend join with `Invoice`

  // data from admin-panel Supplier
  neuerZRStatus: string;
  zrNummer: string;
  zrEnde: string;
  suchname2: string;
  unternehmensnameZUSATZ: string;
  zrBemerkungenzuKreditor_EXTERN: string;
  supplierName: string;
  rg_check: string;
  rg_konto: string;
  rg_paid: string; // System.DateTime
  rg_paid_from: string;
}

export interface Value {
  value: ChangeVoucher[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<ChangeVoucher>): any {
  return {
    id: data?.id || undefined,
    nd_id: data?.nd_id || '',
    process_Id: data?.process_Id || '',
    aa_type_id: data?.aa_type_id || '',
    reason: data?.reason || 0,
    status: data?.status || 0,
    int_status: data?.int_status || 0,
    wv_date: data?.wv_date || undefined,

    reference_id: data?.reference_id || '',
    commission_no: data?.commission_no || '',
    original: data?.original || 0,
    ext_document_no: data?.ext_document_no || '',
    ext_document_date: data?.ext_document_date || '',
    processing_date: data?.processing_date || undefined,
    due_date: data?.due_date || undefined,
    net_amount: data?.net_amount || 0,
    gross_amount: data?.gross_amount || 0,
    currency: data?.currency || '',
    tax_rate: data?.tax_rate || 0,
    tax_amount: data?.tax_amount || 0,
    cash_discount_rate: data?.cash_discount_rate || 0,
    cash_discount_period: data?.cash_discount_period || 0,
    discount_rate: data?.discount_rate || 0,
    discount_amount: data?.discount_amount || 0,
    total_discount_rate: data?.total_discount_rate || 0,
    zr_number_member: data?.zr_number_member || '',
    name_mitglied: data?.name_mitglied || '',
    zr_number_supplier: data?.zr_number_supplier || '',
    rebooking_zr_no: data?.rebooking_zr_no || '',
    name_lieferant: data?.name_lieferant || '',
    total_discount_rate_info: data?.total_discount_rate_info || '',
    attachment: data?.attachment || '',
    end_of_week_date: data?.end_of_week_date || undefined,
    updated_by: data?.updated_by || '',
    updated: data?.updated || undefined,
    created_by: data?.created_by || '',
    created: data?.created || undefined,


    dms_nr_invoice: data?.dms_nr_invoice || '',

    discountable: data?.discountable || false,
    cv_skontosatz: data?.cv_skontosatz === null || data?.cv_skontosatz === undefined ? null : data?.cv_skontosatz,
    cv_belegnr: data?.cv_belegnr || undefined,
    description: data?.description || undefined,

    rg_check: data?.rg_check || '',
    rg_konto: data?.rg_konto || '',
    rg_paid: data?.rg_paid || undefined,
    rg_paid_from: data?.rg_paid_from || '',
  };
}

function parse(data?: Partial<ChangeVoucher>): ChangeVoucher {
  return {
    id: data?.id || '',
    nd_id: data?.nd_id || '',
    process_Id: data?.process_Id || '',
    aa_type_id: data?.aa_type_id || 0,
    reason: data?.reason && data?.reason >= 0 ? data?.reason : undefined,
    status: data?.status || 0,
    int_status: data?.int_status || 0,
    wv_date: data?.wv_date || '',

    reference_id: data?.reference_id || '',
    commission_no: data?.commission_no || '',
    original: data?.original || 0,
    ext_document_no: data?.ext_document_no || '',
    ext_document_date: data?.ext_document_date || new Date().toISOString(),
    processing_date: data?.processing_date || '',
    due_date: data?.due_date || '',
    net_amount: data?.net_amount || 0,
    gross_amount: data?.gross_amount || 0,
    currency: data?.currency || 'EUR',
    tax_rate: data?.tax_rate || undefined,
    tax_amount: data?.tax_amount || 0,
    cash_discount_rate: data?.cash_discount_rate || 0,
    cash_discount_period: data?.cash_discount_period || 0,
    discount_rate: data?.discount_rate || 0,
    discount_amount: data?.discount_amount || 0,
    total_discount_rate: data?.total_discount_rate || 0,
    zr_number_member: data?.zr_number_member || '',
    name_mitglied: data?.name_mitglied || '',
    zr_number_supplier: data?.zr_number_supplier || '',
    rebooking_zr_no: data?.rebooking_zr_no || '',
    name_lieferant: data?.name_lieferant || '',
    total_discount_rate_info: data?.total_discount_rate_info || '',
    attachment: data?.attachment || '',
    end_of_week_date: data?.end_of_week_date || '',
    updated_by: data?.updated_by || '',
    updated: data?.updated || '',
    created_by: data?.created_by || '',
    created: data?.created || '',

    discountable: data?.discountable || false,
    cv_skontosatz: data?.cv_skontosatz === null || data?.cv_skontosatz === undefined ? null : data?.cv_skontosatz,
    cv_belegnr: data?.cv_belegnr || '',
    description: data?.description || '',

    aae_typ_id_frontend: data?.aae_typ_id_frontend || '', // pure frontend

    dms_nr_invoice: data?.dms_nr_invoice || '',

    // from admin-panel
    neuerZRStatus: data?.neuerZRStatus || '',
    zrNummer: data?.zrNummer || '',
    zrEnde: data?.zrEnde || '',
    suchname2: data?.suchname2 || '',
    unternehmensnameZUSATZ: data?.unternehmensnameZUSATZ || '',
    zrBemerkungenzuKreditor_EXTERN: data?.zrBemerkungenzuKreditor_EXTERN || '',
    supplierName: data?.supplierName || '',

    rg_check: data?.rg_check || '',
    rg_konto: data?.rg_konto || '',
    rg_paid: data?.rg_paid || '',
    rg_paid_from: data?.rg_paid_from || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
