import changeVoucherObj from '@/shared/model/changeVoucher';
import changeVoucherSearchData from '@/shared/model/smallPayloadModels/changeVoucherSearchData';
import { ChangeVoucherState } from './types';

export const state = (): ChangeVoucherState => initialState();

export const initialState = (): ChangeVoucherState => ({
  changeVoucherSearchData: changeVoucherSearchData.defaultData(),
  changeVoucherAeaTyps: [],
  changeVoucherStatusTypes: [],
  changeVoucherIntStatusTypes: [],
  changeVoucherMembers: [],
  changeVoucherSuppliers: [],
  changeVoucherMinMaxBruttoNetto: undefined,
  initialLocalStorageChangeVoucherSearchData: undefined,
  changeVoucher: changeVoucherObj.parse({}),
  changeVouchers: {
    items: [],
    isLoading: undefined,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['created'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
      andClauseFieldsIds: [],
    },
  },
});
