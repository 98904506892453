import { GetterTree } from 'vuex';
import { WochenabschluesseState } from './types';
import { RootState } from '../../types';
import { WochenabschluesseSearchData } from '@/shared/model/smallPayloadModels/wochenabschluesseSearchData';

export const getters: GetterTree<WochenabschluesseState, RootState> = {
  ['getZRWochenabschluesse']: (state) => state.wochenabschluesses,
  ['getWochenabschluesseSearchParams']: (state) => state.wochenabschluesses?.searchParams,
  ['getZRRWochenabschluesseIsLoading']: (state) => state.wochenabschluesses?.isLoading,
  ['getWochenabschluesseSearchData']: (state) => state.wochenabschluesseSearchData,
  ['getIsWochenabschluesseFileDownload']: (state) => state.isWochenabschluesseFileDownload,
  ['getInitialLocalStorageWochenabschluesseSearchData']: (state) => state.initialLocalStorageWochenabschluesseSearchData, 

  ['isWochenabschluesseSearchDataInitLocalStorageEmpty']: (state) => {
    const localStorageWochenabschluesseSearchData = state.initialLocalStorageWochenabschluesseSearchData;
    if (!localStorageWochenabschluesseSearchData) return true;

    const searchData: WochenabschluesseSearchData = JSON.parse(localStorageWochenabschluesseSearchData);
    let hasDate = !!searchData.datum ;
    let hasZrNummer = !!searchData.zrNummer;    
    let hasMembers = searchData.receiverNumbers?.length > 0;
    let hasTitles = searchData.titles?.length > 0;

    let isEmpty = !hasDate &&  !hasMembers && !hasZrNummer && !hasTitles;
    return isEmpty;
  },

  ['isWochenabschluesseSearchDataEmpty']: (state) => {
    const localStorageSearchData = state.initialLocalStorageWochenabschluesseSearchData;
    if (!state.wochenabschluesseSearchData) return true;

    const searchData : WochenabschluesseSearchData = state.wochenabschluesseSearchData;
    let hasDate = !!searchData.datum;
    let hasZrNummer = !!searchData.zrNummer;    
    let hasMembers = searchData.receiverNumbers?.length > 0;
    let hasTitles = searchData.titles?.length > 0;    

    let isEmpty = !hasDate &&!hasMembers && !hasTitles;
    return isEmpty;
  },
};
