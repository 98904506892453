import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper, numFields } from '../utils/backendHelper';
import wochenabschluesse, { Wochenabschluesse, Value } from '../model/wochenabschluesse';
import ODataFilterBuilder from 'odata-filter-builder';

import {
  WochenabschluesseFacetSearchData,
    WochenabschluesseSearchData,
} from '../model/smallPayloadModels/wochenabschluesseSearchData';
import i18n from '@/i18n';

const searchableColumns: Map<string, string> = new Map([['zr_number_member', 'Belegnummer_Extern']]);
const defaultOrSearchFields = [
  'zr_number_member',  
];

export interface BackendWochenabschluesse {
  
  getWochenabschluesse: (searchParams: SearchParams, searchData?: any) => AxiosPromise<Value>;
  getWochenabschluesseFacetSearch: (facetSearch: WochenabschluesseFacetSearchData) => AxiosPromise<Value>;  
  getTitlesMembers(zrNummer: string, isUserInAzureAdminGroupGetter: any, isZrNummerFiltering: any): AxiosPromise<any>;
  getSupplierMembers(zrNummer: string, isUserInAzureAdminGroupGetter: any, titles: string, isTitleFiltering: any): AxiosPromise<any>;
  getWochenabschluesseFileDownloadLink(payload: any): AxiosPromise<any>;
  createWochenabschluesseLogRecords(payload: any) : AxiosPromise<any>; 
}

export const defaultBackendWochenabschluesse: BackendWochenabschluesse = {

  getWochenabschluesse(searchParams: any, searchData?: any): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    console.log('---------BACKEND-------------get   Wochenabschluesse--------------------------------------  ===>>> searchData  ', searchData, ' searchParams ', searchParams);
    if (searchParams.andClauseFieldsIds?.length > 0) {
      if (!searchParams.andClauseFieldsIds?.some((x: any) => x.chipField == 'all')) {
        console.log('reset orClauseFieldsIds :>> ');
        searchParams.orClauseFieldsIds = [];
      } else {
        let allChipField = searchParams.andClauseFieldsIds?.find((x: any) => x.chipField == 'all');
        searchParams.filter = allChipField.searchWord;
        searchParams.orClauseFieldsIds = defaultOrSearchFields;
        localStorage.setItem('wochenabschluesse-filter', allChipField.searchWord);
      }
      let andClauseFieldsIds: Array<any> = searchParams.andClauseFieldsIds;

      andClauseFieldsIds.forEach((element: any, index: number) => {
        const chipField = searchableColumns.has(element.chipField)
          ? searchableColumns.get(element.chipField)
          : element.chipField;

        if (numFields.includes(chipField.toLowerCase())) {
          var resultAnd = new ODataFilterBuilder('and');

          resultAnd.ge(chipField, Math.trunc(+element.searchWord), false);
          resultAnd.lt(chipField, Math.trunc(+element.searchWord + 1), false);
          odfb.and(resultAnd);
        } else if (element.chipField == 'all') {
          // do nothing
        } else {
          odfb.contains(`tolower(${chipField})`, element.searchWord.toLowerCase());
        }
      });
    }

    searchParams.filter = searchParams.filter?.replace(',', '.'); // with `,` (comma) get parsing Odata error on backend
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.wochenabschluesseOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    console.log('---------BACKEND-------------get   Wochenabschluesse--------------------------------------  ===>>> url  ', url, '   searchParams.filter  ', searchParams.filter);
    //if (searchParams.filter?.length != 0)
     // {
        url = url.replaceAll('MemberzrNumber', 'zr_number_member').replace('FileNames','file_names_list').replace('EndofWeekDdate','end_of_week_date');
        url = url.replaceAll("contains(tolower(end_of_week_date),","contains(tolower(cast(end_of_week_date, 'Edm.String')),");          
      //}
  
   //FileNames
    console.log('---------BACKEND-------------get   Wochenabschluesse--------------------------------------  ===>>> url  ', url, '   searchData  ', searchData);
    return instance.put<Value>(url, searchData);
  },  
  createWochenabschluesseLogRecords(data: any): AxiosPromise<any> {
    console.log('----------BACKEND---------create WochenabschluesseLogRecords-----------------------------------------  ===>>>data   ', data);
    return instance.put<any>(`${URLS.wochenabschluesse}/Add`, data);
  },
  getWochenabschluesseFacetSearch(facetData: WochenabschluesseFacetSearchData): AxiosPromise<any> {
    let url = `${URLS.wochenabschluesse}/GetSearchResult`;
    console.log('facetData :>> ', facetData);
    return instance.put<Value>(url, facetData);
  }, 
  getTitlesMembers(zrNummer: string, isUserInAzureAdminGroupGetter: any, isZrNummerFiltering: any): AxiosPromise<any> {
    console.log('------BACKEND-------------get TitlesMembers-----------------------------------------  ===>>> isZrNummerFiltering  ', isZrNummerFiltering);
    return instance.get<any>(`${URLS.wochenabschluesse}/GetTitlesMembers/${zrNummer}/${isUserInAzureAdminGroupGetter}/${isZrNummerFiltering}`);
  },
  getSupplierMembers(zrNummer: string, isUserInAzureAdminGroupGetter: any, titles: string, isTitleFiltering: any): AxiosPromise<any> {
    console.log('------BACKEND-------------get  SupplierMembers-----------------------------------------  ===>>> titles  ', titles, '  isTitleFiltering ', isTitleFiltering);
    return instance.get<any>(`${URLS.wochenabschluesse}/GetSupplierMembers/${zrNummer}/${isUserInAzureAdminGroupGetter}/${titles}/${isTitleFiltering}`);
  },
  getWochenabschluesseFileDownloadLink(payload: any): AxiosPromise<any> {
    console.log('--------------------------getWochenabschluesseFileDownloadLink payload ', payload);
    return instance.put<any>(`${URLS.wochenabschluesse}/GetFileDownloadLink`, payload, {
      responseType: 'blob',
    });
  },

};
