import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import searchData, { SearchData } from '@/shared/model/smallPayloadModels/searchData';
import wochenabschluesseSearchData, { WochenabschluesseSearchData } from '@/shared/model/smallPayloadModels/wochenabschluesseSearchData';
import DateField from '@/components/_common/date-field/DateField.vue';
import DateUtils from '@/shared/utils/DateUtils';


const logger = new Logger('search-form');
const authModule = namespace('auth');
const changeVoucherModule = namespace('changeVoucher');
const wochenabschluesseData = namespace('wochenabschluesse');

@Component({
  components: { DateField },
})
export default class SearchFormEndOfWeek extends Vue {
  @Ref('rechnungsnummerCombobox')
  private refRechnungsnummerCombobox!: any;
  @Ref('statusesCombobox')
  private refStatusesCombobox!: any;
  @Ref('membersCombobox')
  private refMembersCombobox!: any;

  @Ref('titlesCombobox')
  private refTitlesCombobox!: any;

  @wochenabschluesseData.Getter('getWochenabschluesseSearchParams')
  private zrWochenabschluesseSearchParams!: any;

  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroupGetter!: any;
  @authModule.Getter('isUserOnlyGs')
  private isUserOnlyGs!: any;

  @authModule.Getter('zrNummer')
  private zrNummer!: any;
  @authModule.Getter('zrNummerWithRoles')
  private zrNummerWithRoles!: any;

  @wochenabschluesseData.Action('getSupplierMembers')
  private actionGetSupplierMembers!: any;

  @wochenabschluesseData.Action('updateWochenabschluesseSearchData')
  private actionUpdateWochenabschluesseSearchData!: any;
  @wochenabschluesseData.Action('getTitlesMembers')
  private actionGetTitlesMembers!: any;
  @wochenabschluesseData.Getter('isWochenabschluesseSearchDataEmpty')
  private isWochenabschluesseSearchDataEmpty!: any;
  @wochenabschluesseData.Getter('resetwochenabschluesseSearchFormData')
  private actionResetwochenabschluesseSearchFormData!: any;

  @wochenabschluesseData.Getter('isWochenabschluesseSearchDataInitLocalStorageEmpty')
  private isWochenabschluesseSearchDataInitLocalStorageEmpty!: any;
   
  @wochenabschluesseData.Getter('getWochenabschluesseSearchData')
  private getWochenabschluesseSearchData!: any;

  @wochenabschluesseData.Action('updateInitialLocalStorageWochenabschluesseSearchData')
  private actionUpdateInitialLocalStorageWochenabschluesseSearchData!: any;

  get isSingleZrNummer() {
    return !!this.zrNummer && !this.zrNummer.includes(',');
  }

  created() {    
    console.log('---created--SEARCH--onOptions FilterChanged------search-form-wochenabschluesse---------onOptions FilterChanged   1-----------------------------------created this.getWochenabschluesseSearchData :>> ', this.getWochenabschluesseSearchData, '  this.getWochenabschluesseSearchData ', this.wochenabschluesseSearchData);
    if (!this.getWochenabschluesseSearchData) {
      console.log('DDDDDDDDefaultData-----------!!!!!!!!!!!!!!!!!!!!!!!--search-form-wochenabschluesse-----------created  -onOptions FilterChanged-  2 ----this.getWochenabschluesseSearchData-----------------------created this.getWochenabschluesseSearchData :>> ', this.getWochenabschluesseSearchData);
       this.getWochenabschluesseSearchData = wochenabschluesseSearchData.defaultData(); // `{}` returned  error         
    }
  }

  async mounted() {
    var promiseAll = [];
    console.log('-------search-form-wochenabschluesse----------moun ted---------created this.getWochenabschluesseSearchData----------------------------------  ===>>>   ', this.isUserInAzureAdminGroupGetter , '    ', (!this.isUserInAzureAdminGroupGetter && !this.isSingleZrNummer));    
    promiseAll.push(this.getMembers(), this.getTitles());

    const localStorageWochenabschluesseSearchData = localStorage.getItem('wochenabschluesseSearchData');
    console.log('-------search-form-wochenabschluesse----------moun ted---------localStorageWochenabschluesseSearchData---------------------------------  ===>>>   ', localStorageWochenabschluesseSearchData);    
    if (!!localStorageWochenabschluesseSearchData) {
      console.log('-------search-form-wochenabschluesse----------moun ted---------!!!! 1 localStorageWochenabschluesseSearchData---------------------------------  ===>>>   ', localStorageWochenabschluesseSearchData);    
      this.actionUpdateInitialLocalStorageWochenabschluesseSearchData(localStorageWochenabschluesseSearchData);
    }
    console.log('-------search-form-wochenabschluesse----------moun ted---------!!!! 000 localStorageWochenabschluesseSearchData---------------------------------  ===>>>   ', localStorageWochenabschluesseSearchData, ' this.isWochenabschluesse SearchDataInitLocalStorageEmpty ', this.isWochenabschluesseSearchDataInitLocalStorageEmpty);    
    if (localStorageWochenabschluesseSearchData && !this.isWochenabschluesseSearchDataInitLocalStorageEmpty) {
      console.log('-------search-form-wochenabschluesse----------moun ted---------!!!! 2 localStorageWochenabschluesseSearchData---------------------------------  ===>>>   ', localStorageWochenabschluesseSearchData);    
      this.wochenabschluesseSearchData = JSON.parse(localStorage.getItem('wochenabschluesseSearchData')!);      
    }
    // else if (localStorageWochenabschluesseSearchData){
    //   this.wochenabschluesseSearchData.isShowAllData=JSON.parse(localStorage.getItem('wochenabschluesseSearchData')!).isShowAllData;     
    // }
    //this.isShowAllData = this.wochenabschluesseSearchData.isShowAllData;
    //this.isShowAllData=this.wochenabschluesseSearchData.isShowAllData;
   // this.wochenabschluesseSearchData = Object.assign({}, this.wochenabschluesseSearchData);
     console.log('-------search-form-wochenabschluesse----------moun ted---------!!!! 003  localStorageWochenabschluesseSearchData------  ' ,this.wochenabschluesseSearchData.isShowAllData);

  }

  isSearchDataWasCleared = false;
  @Watch('isWochenabschluesseSearchDataEmpty', { deep: true })
  public async onIsWochenabschluesseSearchDataEmpty(newVal: boolean, oldVal: boolean) {
    console.log('---------search-form-wochenabschluesse----isWochenabschluesseSearchDataEmpty--------------Watch is WochenabschluesseSearchDataEmpty---------------------------------  ===>>> true  ', newVal);
    logger.log('on Is WochenabschluesseSearchDataEmpty on Is WochenabschluesseSearchDataEmpty :>> ', newVal);
    if (newVal == true) {
      this.isSearchDataWasCleared = true; // avoid eternal loop on clearing `searchData` (watcher glitch without this variable)      
       console.log('DDDDDDDDefaultData---------search-form-wochenabschluesse----isWochenabschluesseSearchDataEmpty------this.isWochenabschluesseSearchDataInitLocalStorageEmpty   ', this.isWochenabschluesseSearchDataInitLocalStorageEmpty);
       this.wochenabschluesseSearchData.datum = wochenabschluesseSearchData.defaultData().datum;  
       this.wochenabschluesseSearchData.receiverNumbers = wochenabschluesseSearchData.defaultData().receiverNumbers;    
       this.wochenabschluesseSearchData.titles = wochenabschluesseSearchData.defaultData().titles;    
       this.wochenabschluesseSearchData.isUserInAzureAdminGroup = wochenabschluesseSearchData.defaultData().isUserInAzureAdminGroup;         
      //this.wochenabschluesseSearchData.isShowAllData = this.isShowAllData;
    }
  }

  @Watch('wochenabschluesseSearchData', { deep: true })
  public async onOptionsFilterChanged(newVal: WochenabschluesseSearchData, oldVal: WochenabschluesseSearchData) {
    // avoid loop when `searchData` was reset
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!--SEARCH---search-form-wochenabschluesse------onOptions FilterChanged-------------------------wochenabschluesse SearchData------------------------  ===>>>   ', this.wochenabschluesseSearchData.receiverNumbers, '  wochenabschluesseSearchData.isShowAllData ', this.wochenabschluesseSearchData.isShowAllData, '    this.isSearchDataWasCleared ', this.isSearchDataWasCleared);
    if (!this.isSearchDataWasCleared) {
      // (GSP-102) Always take into account the `zrNummer` to avoid show all records for `gsUser`
      let payload = Object.assign({}, this.wochenabschluesseSearchData);
      console.log('-----------1 onOptions FilterChanged-------------------------wochenabschluesse SearchData------------------------  ===>>>  payload ', payload);
      payload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
      payload.zrNummer = this.getZrNummerDependOnRole();
      payload.receiverNumbers = this.wochenabschluesseSearchData.receiverNumbers;     
      payload.titles = this.wochenabschluesseSearchData.titles;
      payload.isShowAllData = this.wochenabschluesseSearchData.isShowAllData;
      console.log('!!!!!!!!!!!!! actionUpdateWochenabschluesseSearchData-----------2 onOptions FilterChanged-------------------------wochenabschluesse SearchData------------------------  ===>>>  payload ', payload, ' ayload.receiverNumbers ', payload.receiverNumbers);
      this.actionUpdateWochenabschluesseSearchData(payload); // (GSP-075) use `Object.assign` to break reference for `searchData` to get new data from backend on any input clear or multiple select
    }
    this.isSearchDataWasCleared = false;
  }

  getZrNummerDependOnRole() {
    let result = this.isUserOnlyGs ? (this.zrNummerWithRoles.endOfWeekZrNummer + '') || '-' : this.zrNummer + '';
    return result;
  }

  private clearFacetFilter: boolean = false;
  @Watch('zrWochenabschluesseSearchParams', { deep: true })
  public async onOptionsFacetFilterChanged(newVal: any, oldVal: any) {
    this.clearFacetFilter = newVal.andClauseFieldsIds.length == 0;
  }


  private loadingMembers = false;
  private async getMembers() {
    let zrNummer = this.getZrNummerDependOnRole();
    console.log('--------------------------------------------------SEARCH  Get WochenabschluesseMembers--------------------------------------  ===>>> zrNummer  ', zrNummer);
    this.actionGetSupplierMembers( {zrNummer: zrNummer, isUserInAzureAdminGroupGetter: this.isUserInAzureAdminGroupGetter, titles: null, isTitleFiltering: false} )
        .then((result: any) => {
            this.members = result;
            console.log('--------------------------------------------------actSEARCHion  WochenabschluesseMembers--------------------------------------  ===>>> this.supplierMembers  ', this.members);
        })
        .catch((err: any) => {
            logger.error(err);
        });
  }

  private async getTitles() {
    let zrNummer = this.getZrNummerDependOnRole();
    console.log('--------------------------------------------------SEARCH  GetWochenabschluesse---Titles-----------------------------------  ===>>> zrNummer  ', zrNummer);
    this.actionGetTitlesMembers( {zrNummer: zrNummer, isUserInAzureAdminGroupGetter: this.isUserInAzureAdminGroupGetter, isZrNummerFiltering: false} )
        .then((result: any) => {
            this.titles = result;
            console.log('--------------------------------------------------SEARCH  GetWochenabschluesse---------Titles-----------------------------  ===>>> this.supplierMembers  ', this.members);
        })
        .catch((err: any) => {
            logger.error(err);
        });
  }
  statuses: any = [];
  members = [];
  titles = [];
  //private isShowAllData:boolean = true;
  wochenabschluesseSearchData= wochenabschluesseSearchData.defaultData();

  changeDate(value: any) {
    this.wochenabschluesseSearchData.datum = value || undefined; // new Date(value).toJSON();

    // (GSP-102) Always take into account the `zrNummer` to avoid show all records for `gsUser`
    const payload = Object.assign({}, this.wochenabschluesseSearchData);
    payload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    payload.zrNummer = this.getZrNummerDependOnRole();

    this.wochenabschluesseSearchData = Object.assign({}, payload); // workaround (sometimes data change is just ignored by watcher)
  }

  //#region Filtering autocomplete data based on previous autocomplete values
  // private setSearchFormFiltersPayload() {
  //   let newVal = this.wochenabschluesseSearchData;
  //   let chipDataString= this.zrWochenabschluesseSearchParams.andClauseFieldsIds.map((el: any) => `${el.chipField}:${el.searchWord}`).join(',');
  //   let payload: WochenabschluesseSearchData = {     
  //     datum: newVal.datum,
  //     receiverNumbers: [],
  //     titles:[],
  //     isShowAllData: this.isShowAllData,
  //     isUserInAzureAdminGroup: this.isUserInAzureAdminGroupGetter,
  //     zrNummer: this.getZrNummerDependOnRole(),
  //     chipDataString: chipDataString,
  //   };
  //   return payload;
  // }

  showMenu() {
    this.refRechnungsnummerCombobox.activateMenu();
  }

  reloadRechnungsnummersList: boolean = false;
  reloadStatusesList: boolean = false;
  reloadMembersList: boolean = false;
  reloadSuppliersList: boolean = false;


  //#endregion

  searchRechnungsnummerInput = '';
  searchStatusInput = '';
  searchMembersInput = '';
  searchTitlesInput = '';
  searchModelNameInput = '';
  searchSupplierInput = '';

  clearSearchInput() {
    this.searchRechnungsnummerInput = '';
    this.searchStatusInput = '';
    this.searchMembersInput = '';
    this.searchTitlesInput = '';
    this.searchModelNameInput = '';
    this.searchSupplierInput = '';
  }

  clearSearchFormData() {
    this.actionResetwochenabschluesseSearchFormData();
  }

  private allowedDates(date: string) {
    const dateValue = new Date(date);
    let isFriday = dateValue.getDay() === 5;
    const today = new Date();
    let twoWeeksFromToday = new Date(today.getTime() + 12096e5 - 864e5); // `12096e5` is 14 days in milliseconds // (GSP-098) use 13 days (not 14) for 2 weeks after to be able to select again the Friday that 2 weeks from current Friday (otherwise it will be selected by default but you could not select it again manually)
    //`864e5` = 86400000 is 1 day in milliseconds

    if (this.isTodayMondayOrTuesday()) {
        twoWeeksFromToday = new Date(today.getTime() + 6048e5); // add 7 days in milliseconds to make next Friday valid (in 11 or 10 days (from Mon and Tue), not in 18 and 17 days as was before)
    }

    const isDateMoreThan2Weeks = dateValue > twoWeeksFromToday;

    return isFriday;// && isDateMoreThan2Weeks;
}

private isTodayMondayOrTuesday() {
  const today = new Date();
  const isMondayOrTuesday = today.getDay() === 1 || today.getDay() == 2;
  return isMondayOrTuesday;
}

private changeListData(value: boolean){
  console.log('-----search-form-wochenabschluesse-------------change ListData--------------------------------------  ===>>> fff  ', this.wochenabschluesseSearchData.isShowAllData, ' value ', value);
  //this.wochenabschluesseSearchData.isShowAllData = value;
  //this.wochenabschluesseSearchData = Object.assign({}, this.wochenabschluesseSearchData);
  this.wochenabschluesseSearchData.isShowAllData = value; // new Date(value).toJSON();
  //this.getWochenabschluesseSearchData.isShowAllData = value;
  // (GSP-102) Always take into account the `zrNummer` to avoid show all records for `gsUser`
  const payload = Object.assign({}, this.wochenabschluesseSearchData);
  payload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
  payload.zrNummer = this.getZrNummerDependOnRole();
  console.log('------search-form-wochenabschluesse-----------change ListData--------------------------------------  ===>>> fff payload ', payload, '   ');
  this.wochenabschluesseSearchData = Object.assign({}, payload); 
  console.log('------search-form-wochenabschluesse-----------change ListData--------------------------------------  ===>>> fff wochenabschluesseSearchData ', this.wochenabschluesseSearchData, '   ');
}

filterTitles(){
  console.log('-------------------------------filter Titles-----------------------------  ===>>>  this.members.length ', this.wochenabschluesseSearchData.receiverNumbers, '  length  ', this.wochenabschluesseSearchData.receiverNumbers.length);
  let membersList: string ='';
  let isZrNummerFiltering:boolean = false;
  if (this.wochenabschluesseSearchData.receiverNumbers.length > 0)
  {
    isZrNummerFiltering= true;
    membersList = this.wochenabschluesseSearchData.receiverNumbers.join('*,*');
  } 
  else
  {
    isZrNummerFiltering=false;
    membersList = this.getZrNummerDependOnRole();    
  }   
    console.log('-------------------------------filter Titles-----------------------------  ===>>>  membersList. ', membersList);
    this.actionGetTitlesMembers( {zrNummer: membersList, isUserInAzureAdminGroupGetter: this.isUserInAzureAdminGroupGetter, isZrNummerFiltering: isZrNummerFiltering} )
    .then((result: any) => {
        this.titles = result;
        console.log('--------------------------------------------------action  GetInvoiceMembers--------------------------------------  ===>>> this.supplierMembers  ', this.members);
    })
    .catch((err: any) => {
        logger.error(err);
    });
}

filterMembers()
{  
  console.log('---------------------search-form-wochenabschluesse-----------------------------  filter Members--------------------------------------  ===>>> this.wochenabschluesseSearchData.titles.length  ', this.wochenabschluesseSearchData.titles.length, '  wochenabschluesseSearchData ', this.wochenabschluesseSearchData);
  let titlesList: any ='';
  let isTitleFiltering:boolean = false;
  if (this.wochenabschluesseSearchData.titles.length > 0)
  {
    isTitleFiltering=true;
    titlesList = this.wochenabschluesseSearchData.titles.join(',');
  }
  else
  {    
    titlesList = null;
    isTitleFiltering=false; 
  } 
  let zrNummer = this.getZrNummerDependOnRole();
    console.log('--------------------------------------------------action  GetInvoiceMembers--------------------------------------  ===>>> zrNummer  ', zrNummer, ' titlesList  ', titlesList);
    this.actionGetSupplierMembers( {zrNummer: zrNummer, isUserInAzureAdminGroupGetter: this.isUserInAzureAdminGroupGetter, titles: titlesList, isTitleFiltering: isTitleFiltering} )
        .then((result: any) => {
            this.members = result;
          
        })
        .catch((err: any) => {
            logger.error(err);
        });

}
  async clearFilterMembers()
  {
    console.log('----------------------clear FilterMember------------clear FilterMember--------------------------  ===>>>   ');
  //await this.getMembers();
  }

  async clearFilterTitles() {
    //await this.getTitles();
  }
}
