import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import searchData, { SearchData } from '@/shared/model/smallPayloadModels/searchData';
import { ROUTES } from '@/router/routesEnum';
import zrStat, { StatusIndices as ZrStatus, zrStatusesList } from '@/shared/model/smallPayloadModels/zrStatus';
import DateField from '@/components/_common/date-field/DateField.vue';
import { CONST } from '@/shared/utils/Constants';
import { UserData } from '@/shared/model/userData';
import zrDoc, { ZRDocument } from '@/shared/model/zrDocument';
import StatusDialog from './status-dialog/status-dialog.vue';
import { DefaultBackendHelper } from '@/shared/utils/backendHelper';
import GeneralUtils from '@/shared/utils/generalUtils';
import SupplierTooltip from './search-form/supplier-tooltip/supplier-tooltip.vue';

const logger = new Logger('login');
const zrDocumentModule = namespace('zrDocument');
const authModule = namespace('auth');
const userDataModule = namespace('userData');

@Component({
  components: { D4yTable, DateField, StatusDialog, SupplierTooltip },
})
export default class ZRDocuments extends Vue {
  @authModule.Action('canSeeAll')
  private actionCanSeeAll!: any;
  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroupGetter!: any;
  @authModule.Getter('isUserSuperAdmin')
  private isUserSuperAdmin!: any;
  @authModule.Getter('isUserGsUser')
  private isUserGsUser!: any;
  @authModule.Getter('isUserOnlyGs')
  private isUserOnlyGs!: any;
  @authModule.Getter('zrNummer')
  private zrNummer!: any;
  @authModule.Getter('zrNummerWithRoles')
  private zrNummerWithRoles!: any;
  @authModule.Getter('isUserSupport')
  private isUserSupport!: any;
  @authModule.Getter('isInitialRedirect')
  private isInitialRedirectGetter!: any;
  @authModule.Getter('supplierTooltipInfo')
  private supplierTooltipInfo!: any;

  @authModule.Getter('isUserHasRightsForDocuments')
  private isUserHasRightsForDocuments!: any;

  @zrDocumentModule.Action('getZRDocuments')
  private actionGetZRDocuments!: any;
  @zrDocumentModule.Action('getZRDocumentsFacetSearch')
  private actionGetZRDocumentsFacetSearch!: any;
  @zrDocumentModule.Action('getZrDocumentsExcelReport')
  private actionGetZrDocumentsExcelReport!: any;
  @zrDocumentModule.Action('getZrDocumentsPdfReport')
  private actionGetZrDocumentsPdfReport!: any;
  @zrDocumentModule.Action('updateZRDocumentStatus')
  private actionUpdateZRDocumentStatus!: any;
  @zrDocumentModule.Getter('getZRDocuments')
  private getZRDocuments!: any;
  @zrDocumentModule.Getter('getZRDocumentsIsLoading')
  private zrDocumentsIsLoading!: boolean;
  @zrDocumentModule.Getter('getZRDocumentsSearchParams')
  private zrDocumentsSearchParams!: any;
  @zrDocumentModule.Getter('isSearchDataEmpty')
  private isSearchDataEmpty!: any;
  @zrDocumentModule.Getter('getZrDocumentExcelIsLoading')
  private getZrDocumentExcelIsLoading!: any;
  @zrDocumentModule.Getter('getZrDocumentPdfIsLoading')
  private getZrDocumentPdfIsLoading!: any;

  @zrDocumentModule.Action('updateSearchData')
  private actionUpdateSearchData!: any;
  @zrDocumentModule.Getter('getSearchData')
  private getSearchData!: any;

  @userDataModule.Action('updateUserData')
  private actionUpdateUserData!: any;
  @userDataModule.Action('getUserData')
  private actionGetUserData!: any;

  private previousTimeoutId: any = 0;
  private searchPayload: any = {};
  @Watch('getSearchData', { deep: true })
  public async onOptionsFilterChanged(newVal: SearchData, oldVal: SearchData) {
    const areObjectsEqual = GeneralUtils.areObjectsEqual(newVal, oldVal, ['isUserInAzureAdminGroup', 'zrNummer']); // decided to ignore 2 props than include logic for them in `search-form` component

    console.log('areObjectsEqual :>> ', areObjectsEqual);
    logger.debug(`----filter:${oldVal}->${newVal}`);
    logger.log('newVal :>> ', newVal);
    logger.log('oldVal :>> ', oldVal);

    if (areObjectsEqual || this.isQueryBeforeCreatedDataLoad) {
      return;
    }

    let payload: any = {
      searchData: {
        rechnungsnummer: newVal.rechnungsnummer,
        status: newVal.status?.map((x) => +x),
        mitglied: newVal.mitglied,
        lieferant: newVal.lieferant,
        datum: newVal.datum,
      },
    };
    this.searchPayload = payload.searchData;
    localStorage.setItem('searchData', JSON.stringify(payload.searchData));

    if (!localStorage.getItem('zr-documents-filter')) {
      this.zrDocumentsSearchParams.filter = ''; // clear Odata filter, cleared here to avoid extra backend query from `@update:options="selectionUpdateOptions"`
    }

    // set correct payload for `download PDF/excel` request
    this.getSearchData.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    this.getSearchData.zrNummer = this.getZrNummerDependOnRole();

    // ignore request to backend until rights checked and tabled showed
    // showed use after `localStorage.setItem('searchData'` to work properly
    if (!this.isUserLoggedInAndHasRights) return;

    payload.searchData.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    if (this.isUserGsUser) {
      console.log('this.zrNummer onOptionsFilterChanged :>> ', this.zrNummer);
      payload.searchData.zrNummer = this.getZrNummerDependOnRole(); // this.zrNummer;
    }

    this.zrDocumentsSearchParams.dataOption.page = 1; // (GSP-082) go to 1st page, when change filter
    await this.actionGetZRDocuments(payload)
      .then((result: any) => {})
      .catch((err: any) => {
        logger.error(err);
      });

    await this.updateSearchDataBasedOnLastUpdate();
  }

  getZrNummerDependOnRole() {
    let result = this.isUserOnlyGs ? (this.zrNummerWithRoles.zrdocumentZrNummer + '') || '-' : this.zrNummer + '';
    console.log('------------DOCUMENT---------------get ZrNummerDependOnRole---------------------------------  ===>>> result  ', result);
    return result;
  }
  get isUserLoggedInAndHasRights() {
    return (
      (this.isUserInAzureAdminGroupGetter || (this.isUserGsUser && !!this.zrNummer)) && this.isUserHasRightsForDocuments
    );
  }

  // avoid extra query from watcher on initial page load
  get documentsLoaded() {
    return this.zrDocumentsIsLoading !== undefined;
  }
  get isQueryBeforeCreatedDataLoad() {
    return this.zrDocumentsIsLoading === undefined;
  }

  private async updateSearchDataBasedOnLastUpdate() {
    let timeFromLastSearchDataUpdateInSeconds = this.geTimeFromLastSearchDataUpdateInSeconds();
    // avoid extra queries to database , but save immediately when clear all filters
    if (timeFromLastSearchDataUpdateInSeconds > 1.5 || this.isSearchDataEmpty) {
      await this.updateSearchDataInDatabase();
    } else {
      clearTimeout(this.previousTimeoutId);
      this.previousTimeoutId = setTimeout(() => this.updateSearchDataInDatabase(), 2000);
    }
  }

  private geTimeFromLastSearchDataUpdateInSeconds() {
    let timeFromLastSearchDataUpdateInSeconds = 0;
    if (!localStorage.getItem('searchDataLastUpdate')) {
      localStorage.setItem('searchDataLastUpdate', new Date().getTime() + '');
    } else {
      const currentTime = new Date().getTime();
      timeFromLastSearchDataUpdateInSeconds = (currentTime - +localStorage.getItem('searchDataLastUpdate')!) / 1000;
      localStorage.setItem('searchDataLastUpdate', new Date().getTime() + '');
    }
    console.log('timeFromLastUpdate :>> ', timeFromLastSearchDataUpdateInSeconds);
    return timeFromLastSearchDataUpdateInSeconds;
  }

  async updateSearchDataInDatabase() {
    const userDataPayload = {
      searchData: {
        searchData: this.searchPayload,
        odataFilter: localStorage.getItem('zr-documents-filter') || '',
        chipDataString: this.chipDataString || '',
      },
      isDocumentSearch: true,
    };

    await this.actionUpdateUserData(userDataPayload)
      .then((result: any) => {})
      .catch((err: any) => {
        logger.error(err);
      });
  }

  get tableText() {
    return `${this.getZRDocuments.total} Artikel`;
  }

  get initialRedirect() {
    return this.isInitialRedirectGetter;
  }

  get isAllFiltersEmpty() {
    return this.isSearchDataEmpty && !this.zrDocumentsSearchParams.filter && !this.chipDataString;
  }

  get isEmptyMitglied() {
    return this.getSearchData?.mitglied?.length == 0 || !this.getSearchData?.mitglied;
  }

  get isEmptyMitgliedListForGsUser() {
    return this.isUserOnlyGs && this.isEmptyMitglied;
  }

  // (GSP-103) show user ZrNummer for `GsUser` if nothing selected in combobox
  get mitgliedList() {
    return this.isEmptyMitgliedListForGsUser
      ? this.getZrNummerDependOnRole()
      : this.getSearchData?.mitglied?.join(', ');
    // return  this.getSearchData?.mitglied?.join(', ');
  }

  async created() {
    await this.actionCanSeeAll().catch((err: any) => {
      logger.error(err);
    });

    if (this.initialRedirect) return;

    console.log('created zr-documents :>> ');

    localStorage.removeItem('searchDataLastUpdate'); // reset (make null) localsStorage update time on reload
    await this.actionGetUserData(CONST.emptyGuid).then((result: UserData) => {
      if (result?.searchData) {
        logger.log('actionGetUserData result:>> ', result);
        const searchData = JSON.parse(result.searchData);
        this.searchPayload = searchData.searchData;

        this.actionUpdateSearchData(searchData.searchData);
        localStorage.setItem('zr-documents-filter', searchData.odataFilter);

        if (searchData.chipDataString) {
          localStorage.setItem('zr-documents-chipdata', searchData.chipDataString);
          this.parseLocalStorageChips(searchData.chipDataString);
        }
      }
    });

    if (this.isUserLoggedInAndHasRights) {
      await this.getZRDocumentsData();
    }
  }

  parseLocalStorageChips(chipDataStr: any) {
    const values = chipDataStr.split(',');
    this.zrDocumentsSearchParams.andClauseFieldsIds = []; // (GSP-095) avoid duplication in `andClauseFieldsIds` (when return from any other component (any form, invoices list))
    values.forEach((element: string) => {
      const field = element.split(':')[0];
      const word = element.split(':')[1];
      const fieldNameTranslated = this.$i18n.tc(`facet_search.${field}`);
      this.chipData.push({
        chipText: `${fieldNameTranslated}: ${word}`,
        chipField: field,
        searchWord: word,
      });

      this.zrDocumentsSearchParams.andClauseFieldsIds.push({
        chipField: field,
        searchWord: word,
      });
    });
  }

  private isMounted = false; // avoid initial extra request in `selectionUpdateOptions`
  async mounted() {
    this.isMounted = true;
  }

  private async getZRDocumentsData() {
    // don't take getter value in this case
    if (localStorage.getItem('zr-documents-filter')) {
      this.zrDocumentsSearchParams.filter = localStorage.getItem('zr-documents-filter');
    }
    this.searchPayload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    if (this.isUserGsUser) {
      this.searchPayload.zrNummer = this.getZrNummerDependOnRole();
    }

    const payload = { searchParams: this.zrDocumentsSearchParams, searchData: this.searchPayload }; // sent search request from localStorage on reload
    await this.actionGetZRDocuments(payload)
      .then((result: any) => {
        logger.log('result :>> ', result);
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  // item = {
  //   supplier: 'ALFONS VENJAKOB GMBH & CO. KG MOEBELFABRIK',
  //   name: 'Beistelltisch Höhe K 49 cm_1800 Filz-Tablett aufgelegt',
  //   van: '123456',
  //   ean: '123456',
  //   han: '123456',
  //   preisbindung: '2022-04-14',
  // };
  // items = Array(10).fill(this.item);

  get items() {
    console.log('------------------------------------------------------------  ===>>>  this.getZRDocuments.items  ', this.getZRDocuments.items);
    return this.getZRDocuments.items;
  }

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      align?: string;
      class?: string | string[];
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('investment_date'), value: 'belegdatum' },
      { text: this.$t('invoice_number'), value: 'belegnummer_Extern' },
      { text: this.$t('commission_number'), value: 'kommission_Nummer' },
      { text: this.$t('amount_gross'), value: 'betrag_Brutto', align: 'right' },
      { text: this.$t('amount_net'), value: 'betrag_Netto', align: 'right' },
      { text: this.$t('sender'), value: 'zrNummer_Lieferant' },
      { text: this.$t('lieferant_name'), value: 'name_Lieferant' },
      { text: this.$t('receiver'), value: 'zrNummer_Empfaenger' },
      { text: this.$t('release_status'), value: 'status_Freigabe' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
      },
    ];
    return headers;
  }

  private openZrDocument(item: any) {
    logger.log('openZrDocument item :>> ', item);

    this.$router.push({
      name: ROUTES.zrDocument,
      params: { zrDocumentId: item.id },
    });
  }

  clearAllFilters() {
    localStorage.removeItem('searchData');
    localStorage.removeItem('searchDataLastUpdate');

    // (GSP-102) when used only `searchData.defaultData()` on clear the `zrNummer` where dropped and next request was without it returning ALL records
    const zrNummerObj = {
      isUserInAzureAdminGroup: this.isUserInAzureAdminGroupGetter,
      zrNummer: this.getZrNummerDependOnRole(),
    };
    const data = { ...searchData.defaultData(), ...zrNummerObj };
    // (GSP-102) need this trick with different payload to trigger request in watcher otherwise the old/new object will be considered `EQUAL` and request will not be sent
    const payload = this.getSearchData.mitglied ? Object.assign({}, zrNummerObj) : Object.assign({}, data);

    this.actionUpdateSearchData(payload);

    this.resetAllFacetOptionsAndSearch();
  }

  private async selectionUpdateOptions(newVal: any, action: any) {
    console.log('selectionUpdateOptions :>> ', newVal);
    console.log('action :>> ', action);
    // this.zrDocumentsSearchParams.dataOption = newVal;
    // this.zrDocumentsSearchParams.filter = newVal.filter;
    // if (newVal.filter !== undefined) {
    //   localStorage.setItem('zr-documents-filter', newVal.filter);
    // }
    // if (this.isMounted && this.zrDocumentsIsLoading !== undefined) this.getZRDocumentsWithParams();
    if (action == 'search') {
      this.searchFacetModeInDocuments(newVal);
    } else if (action == 'reset') {
      this.resetAllFacetOptionsAndSearch();
      this.getZRDocumentsWithParams();
    } else {
      if (this.isMounted && this.zrDocumentsIsLoading !== undefined) {
        this.zrDocumentsSearchParams.dataOption = newVal;
        // this.zrDocumentsSearchParams.filter = newVal.filter;
        this.getZRDocumentsWithParams();
      }
    }
  }
  private async getZRDocumentsWithParams() {
    this.searchPayload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    if (this.isUserGsUser) {
      this.searchPayload.zrNummer = this.getZrNummerDependOnRole();
    }
    
    await this.actionGetZRDocuments({
      searchParams: this.zrDocumentsSearchParams,
      searchData: this.searchPayload,
    }).then(async () => {
      await this.updateSearchDataBasedOnLastUpdate();
    });
  }

  get isHasSearchData() {
    return this.getZRDocuments.total === 0 || this.zrDocumentsIsLoading || this.isSearchDataHasNoValues();
  }

  isSearchDataHasNoValues() {
    let values = Object.values(this.getSearchData);
    let result = values.some((x: any) => (x + '').length > 0);
    return !result;
  }

  excelDownload() {
    this.setZrNummerInPayloadIfEmpty();
    let payload = this.getSearchData;
    payload.searchTerm = this.zrDocumentsSearchParams.filter;
    payload.chipDataString = this.chipDataString;
    payload.DateTimeTitle =
      this.$i18n.locale == 'de' ? new Date().toLocaleString('de') : new Date().toLocaleString('en');
    payload.lang = this.$i18n.locale;
    this.actionGetZrDocumentsExcelReport(payload);
  }

  get statuses() {
    return {
      1: { text: this.$t('status_chip.new'), class: 'none' },
      2: { text: this.$t('status_chip.book'), class: 'green' },
      3: { text: this.$t('status_chip.not_book'), class: 'red' },
      4: { text: this.$t('status_chip.rejected'), class: 'red' },
      5: { text: this.$t('status_chip.posted'), class: 'green' },
      6: { text: this.$t('status_chip.error'), class: 'orange' },
    };
  }

  get apiStatuses() {
    return [4, 5, 6];
  }

  //#region Logic related to `edit-status`  dialog menu
  currentItem = zrDoc.parse({});
  showMenu = false;
  x = 0;
  y = 0;

  onShowMenu(e: any, item: any) {
    this.currentItem = item;

    let target = e.target;
    // if click on the `chip text`use parent as target (chip element)
    if (e.target.className.includes('v-chip__content')) {
      target = e.target.parentElement;
    }

    const rectCoordinates = target.getBoundingClientRect();
    e.preventDefault();
    this.showMenu = false;
    // this.x = e.clientX;
    // this.y = e.clientY;
    this.x = rectCoordinates.left;
    this.y = rectCoordinates.bottom + 5;

    this.$nextTick(() => {
      this.showMenu = true;
    });
  }
  closeMenu() {
    this.showMenu = false;
  }
  //#endregion

  //#region Facet
  private facetSearch = false;
  private menuDisabled = true;
  private searchWord = '';
  private menuItems: any = [];
  private chipData: Array<any> = [];

  get chipDataString() {
    return this.chipData.map((el: any) => `${el.chipField}:${el.searchWord}`).join(',');
  }

  removeChip(chipText: any) {
    const index = this.chipData.findIndex((x) => x.chipText === chipText);
    this.chipData.splice(index, 1);
    const indexOdata = this.zrDocumentsSearchParams.andClauseFieldsIds.findIndex((x: any) => x.chipText === chipText);
    this.zrDocumentsSearchParams.andClauseFieldsIds.splice(index, 1);

    this.menuItems = [];
    this.menuDisabled = true;
    this.getZRDocumentsWithParams();
  }

  private loadingFacet = false;
  /**
   * Make `facet search` in `zrDocuments` to know in which `zrDocuments` fields specified word was found
   * show `No results` if word was not found in any fields
   * @param {KeyboardEvent} e as KeyboardEvent (contains word from input)
   */
  private searchFacetModeInDocuments(e: any): void {
    const searchWord: string = e.filter;

    let selectedListItemId = document.querySelector('.v-list-item--highlighted .v-list-item__title')?.id;
    if (selectedListItemId != undefined) {
      let menuItem = this.menuItems.find((x: any) => x.field == selectedListItemId);
      this.facetSearch = false;
      this.searchInRealObjects(menuItem);
      return;
    }
    this.searchWord = searchWord;
    // if user press "enter" key again then make search in all fields
    if (this.facetSearch && this.menuItems.length != 0) {
      this.facetSearch = false;
      // return;
    }
    // handle facet search for words more than 2 letters
    if (searchWord?.length >= 2) {
      this.loadingFacet = true;
      // const { start, end } = this.getStartEndPeriodForSearch();
      this.makeFacetSearch(searchWord)
        .then((result: any) => {
          this.formatResultFromSearch(result);
          this.facetSearch = true;
          this.menuDisabled = false;
        })
        .finally(() => {
          this.loadingFacet = false;
        });
    } else if (searchWord?.trim().length === 0) {
      // load all documents if press `Enter` with clear input
      this.getZRDocumentsWithParams();
    }
  }

  async makeFacetSearch(searchWord: any) {
    let payload = {
      searchWord: searchWord,
      chipDataString: this.chipDataString,
    };
    // (GSP-102) always set correct payload for `ZrNummer` for GsUser to avoid show/search all records
    this.getSearchData.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    this.getSearchData.zrNummer = this.getZrNummerDependOnRole();
    payload = { ...this.getSearchData, ...payload };

    return this.actionGetZRDocumentsFacetSearch(payload);
  }

  searchInRealObjects(item: any) {
    this.searchInRealZrDocuments(item);
  }

  /**
   * Make the actual search in the `zrDocuments` table with returning `zrDocuments` based on results from `facet search`
   * @param {object} Menu item `object` with title(shown in menu) and field(backend field name) properties
   */
  searchInRealZrDocuments(item: any) {
    const searchParams = this.zrDocumentsSearchParams; //this.searchParams;

    searchParams.andClauseFieldsIds.push({
      chipField: item.field,
      searchWord: this.searchWord,
    });
    if (item.field === 'all') {
      // searchParams.andClauseFieldsIds.push({
      //   chipField: item.field,
      //   searchWord: this.searchWord,
      // });
    }
    if (this.searchWord?.length >= 2 && item.title.length > 0) {
      // load all documents if clear the input
      this.actionGetZRDocuments({ searchParams, searchData: this.getSearchData })
        .then((result: any) => {
          this.menuDisabled = true;
          if (item.field != 'all') {
            this.chipData.push({
              chipText: `${item.fieldTranslated}: ${this.searchWord}`,
              chipField: item.field,
              searchWord: this.searchWord,
            });
            localStorage.setItem('zr-documents-chipdata', this.chipDataString);
            this.updateSearchDataBasedOnLastUpdate();
          }
        })
        .finally(() => {
          this.resetFacetMenu();
        });
    }
  }

  formatResultFromSearch(result: any) {
    const fieldsWithResult = Object.keys(result).filter((key) => result[key] > 0);
    this.resetMenuItems();
    fieldsWithResult.forEach((element: string) => {
      const fieldName = element.charAt(0).toUpperCase() + element.slice(1);

      const fieldNameTranslated = this.$i18n.tc(`facet_search.${fieldName}`);
      this.menuItems.push({
        title: `${fieldName}: ${this.searchWord} (${result[element]})`,
        titleTranslated: `${fieldNameTranslated}: ${this.searchWord} (${result[element]})`,
        fieldTranslated: fieldNameTranslated,
        field: fieldName,
      });
    });
    if (fieldsWithResult.length === 0) {
      this.menuItems.push({
        titleTranslated: this.$i18n.tc('facet_search.no_results'),
      });
      return;
    }
    this.menuItems.unshift({
      titleTranslated: this.$i18n.tc('facet_search.all'),
      field: 'all',
      title: 'All',
    });
  }

  /**
    Should reset `menuItems` before add result from the next search otherwise the previous result will be summed with current result
    */
  private resetMenuItems() {
    this.menuItems = [];
  }
  private resetChips() {
    this.chipData = [];
  }

  resetFacetMenu() {
    this.menuDisabled = true;
    this.facetSearch = false;
    this.searchWord = '';
  }

  resetAllFacetOptionsAndSearch() {
    this.resetChips();
    this.resetMenuItems();
    this.resetFacetMenu();
    this.zrDocumentsSearchParams.andClauseFieldsIds = [];
    this.zrDocumentsSearchParams.filter = '';
  }

  get searchMenuObj() {
    return {
      facetSearch: this.facetSearch,
      menuDisabled: this.menuDisabled,
      menuItems: this.menuItems,
      hasResults: this.hasResults,
      chipData: this.chipData,
    };
  }

  // on prod for some reason no request for `zrDocuments` and `zrNummer` is empty (load all records but should load 0)
  setZrNummerInPayloadIfEmpty() {
    // set correct payload for `download PDF/excel` request
    this.getSearchData.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    this.getSearchData.zrNummer = this.getZrNummerDependOnRole();
  }

  pdfDownload() {
    this.setZrNummerInPayloadIfEmpty();
    let payload = this.getSearchData;
    payload.searchTerm = this.zrDocumentsSearchParams.filter;
    payload.DateTimeTitle =
      this.$i18n.locale == 'de' ? new Date().toLocaleString('de') : new Date().toLocaleString('en');
    payload.lang = this.$i18n.locale;
    payload.chipDataString = this.chipDataString;
    this.actionGetZrDocumentsPdfReport(payload);
  }

  get hasResults() {
    return this.menuItems.length > 0 && !!this.menuItems[0]?.field;
  }
  //#endregion

  getCurrencySymbol(currency: any) {
    let currencySymbol: string = '';
    if (currency == 'euro' || currency == 'eur' || currency == '') {
      currencySymbol = ' €';
    } else if (currency == 'chf') {
      currencySymbol = ' ₣';
    }

    return currencySymbol;
  }
}
