import { AxiosPromise } from 'axios';
import { instance } from '.';
import { Credentials } from '../model/smallPayloadModels/credentials';
import { URLS } from './index';
import AuthUtils from '../utils/authUtils';


// TODO: (GSP-060) move to AuthUtils.ts
// export const vmeGlobalAdminGroup = 'aad-app-gsproz-vmefa-prod';
// export const vmeGlobalAdminGroupId = window.location.host.includes('dev4you')
//   ? 'bb32d32a-39ba-45bf-b91a-86fada943c73f'
//   : 'f5e8fb2f-22f5-4f54-81c5-677a7ff6b6e7';
// export const vmeSupportGroup = 'aad-app-gsproz-vmesupport-prod';
// export const vmeSupportGroupId = window.location.host.includes('dev4you')
//   ? '33984c7c-116c-49d4-9f89-dd5f8ff87f11'
//   : '9cd6499f-9f14-47b1-82e4-2607833e15ce';
// export const vmeSingleCompanyAdminGroup = 'aad-app-gsproz-gsuser-prod';
// export const vmeSingleCompanyAdminGroupId = window.location.host.includes('dev4you')
//   ? 'c3f8074c-2fc4-47e9-b58b-6333b936fb4e'
//   : '087861a8-e957-4cdc-a491-105816026e87';
// export const vmePortalUserGroup = 'aad-app-vme-portal';
// export const vmePortalUserGroupId = window.location.host.includes('dev4you')
//   ? 'c3f8074c-2fc4-47e9-b58b-6333b936fb4e'
//   : 'ab778d74-cc6b-4c20-a9fe-6845f776284d';

// Test group for local development
//  export const vmeGlobalAdminGroup = 'vme-admin';
//  export const vmeGlobalAdminGroupId = 'b9d13f54-7fe5-452c-8b1d-24db1e8c1875';
//  export const vmeSupportGroup = 'vme-admin';
//  export const vmeSupportGroupId = 'b9d13f54-7fe5-452c-8b1d-24db1e8c1875';
//  export const vmeSingleCompanyAdminGroup = 'vme-admin';
//  export const vmeSingleCompanyAdminGroupId = 'b9d13f54-7fe5-452c-8b1d-24db1e8c1875';
//  export const vmePortalUserGroup = 'vme-admin';
//  export const vmePortalUserGroupId = 'b9d13f54-7fe5-452c-8b1d-24db1e8c1875';

// TODO: (GSP-0) if work then delete above commented code
export const vmeSupportGroup = AuthUtils.getVmeSupportGroup().name;
export const vmeSupportGroupId = AuthUtils.getVmeSupportGroup().groupId;
export const vmeGlobalAdminGroup = AuthUtils.getVmeGlobalAdminGroup().name;
export const vmeGlobalAdminGroupId = AuthUtils.getVmeGlobalAdminGroup().groupId;
export const vmeSingleCompanyAdminGroup =  AuthUtils.getVmeSingleCompanyAdminGroup().name;
export const vmeSingleCompanyAdminGroupId = AuthUtils.getVmeSingleCompanyAdminGroup().groupId;
export const vmePortalUserGroup = AuthUtils.getVmePortalUserGroup().name;
export const vmePortalUserGroupId = AuthUtils.getVmePortalUserGroup().groupId;

export interface BackendAccount {
  authenticate: (
    username: string,
    password: string,
    rememberMe?: boolean
  ) => AxiosPromise<{
    userName: string;
    userTypeName: string;
    companyId: string;
    userId: string;
    refresh_token: string;
  }>;

  getMyAccount: () => AxiosPromise<any>;
  logout: () => AxiosPromise;

  canSeeAllZrDocuments: (id: string) => AxiosPromise<any>;
  getZrNummer: () => AxiosPromise<any>;
  getSupplierTooltipInfo: () => AxiosPromise<any>;
  getTestInvoiceGsUserZr: () => AxiosPromise<any>;
  getEnvironment():  AxiosPromise<any>;
}

export const defaultBackendAccount: BackendAccount = {
  authenticate(
    username: string,
    password: string,
    rememberMe?: boolean
  ): AxiosPromise<{
    userName: string;
    userTypeName: string;
    companyId: string;
    userId: string;
    refresh_token: string;
  }> {
    const credentials: Credentials = {
      password,
      username,
      rememberMe,
      authorities: [],
      emailConfirmed: false,
      inactivityMinutes: 0,
    };
    return instance.post<any>(`${URLS.authenticate}`, credentials);
  },

  getZrNummer(): AxiosPromise<any> {
    return instance.get<any>(`${URLS.invoice}/GetZrNummer`);
  },
  getSupplierTooltipInfo(): AxiosPromise<any> {
    return instance.get<any>(`${URLS.zrDocument}/GetSupplierTooltipInfo`);
  },
  getTestInvoiceGsUserZr(): AxiosPromise<any> {
    return instance.get<any>(`${URLS.invoice}/GetTestInvoiceGsUserZr`);
  },

  getMyAccount(): AxiosPromise<any> {
    return instance.get<any>(`${URLS.account}/GetMe`);
  },
  logout(): AxiosPromise {
    return instance.post(`${URLS.account}/logout`);
  },

  canSeeAllZrDocuments(azureUserId: string): AxiosPromise<any> {
    return instance.get<any>(
      `https://graph.microsoft.com/v1.0/me/memberOf/microsoft.graph.group?$count=true&$orderby=displayName&$search="displayName:${vmeGlobalAdminGroup}" OR "displayName:${vmeSupportGroup}" OR "displayName:${vmeSingleCompanyAdminGroup}" OR "displayName:${vmePortalUserGroup}"&$select=displayName,id`
    );
  },
  getEnvironment(): AxiosPromise<any> {
    return instance.get<any>(`${URLS.invoice}/GetEnvironmentName`);
  },
};
