import { GetterTree } from 'vuex';
import { ZrNewsState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ZrNewsState, RootState> = {
  ['getZrNews']: (state) => state.ZrNews,
  ['getZrNewsIsLoading']: (state) => state.ZrNews?.isLoading,
  ['getZrNewsTotal']: (state) => state.ZrNews?.total,
  ['getZrNewsSearchParams']: (state) => state.ZrNews?.searchParams,

  ['getUnreadNews']: (state) => state.unreadNews,
  ['getZrNewsImages']: (state) => state.zrNewsImages,
  ['isTableView']: (state) => state.isTableView,
};
