import i18n from "@/i18n";
export interface DocumentType {
  index: number;
  i18nKey: string;
}

export enum DocumentTypeNameKey {
  invoice = 'invoice',
  credit = 'credit',
  cancel = 'cancel',
  value_date_change_minus = 'value_date_change_minus',
  transfer = 'transfer',
  minus_carry = 'minus_carry',
  penalty = 'penalty',
  bonus = 'bonus',
  debit = 'debit',
  invoice_no_commission = 'invoice_no_commission',
  credit_no_commission = 'credit_no_commission',
  cancel_no_commission = 'cancel_no_commission',
  value_date_change_plus = 'value_date_change_plus',
  rebooking = 'rebooking',
  debit_no_commission = 'debit_no_commission',
}

const RootI18nKey = 'document_type.';

export const documentTypeList: DocumentType[] = [
  { index: 1, i18nKey: RootI18nKey + DocumentTypeNameKey.invoice },
  { index: 2, i18nKey: RootI18nKey + DocumentTypeNameKey.credit },
  { index: 3, i18nKey: RootI18nKey + DocumentTypeNameKey.cancel },
  { index: 4, i18nKey: RootI18nKey + DocumentTypeNameKey.value_date_change_minus },
  { index: 5, i18nKey: RootI18nKey + DocumentTypeNameKey.transfer },
  { index: 6, i18nKey: RootI18nKey + DocumentTypeNameKey.minus_carry },
  { index: 8, i18nKey: RootI18nKey + DocumentTypeNameKey.penalty },
  { index: 9, i18nKey: RootI18nKey + DocumentTypeNameKey.bonus },
  { index: 10, i18nKey: RootI18nKey + DocumentTypeNameKey.debit },
  { index: 11, i18nKey: RootI18nKey + DocumentTypeNameKey.invoice_no_commission },
  { index: 12, i18nKey: RootI18nKey + DocumentTypeNameKey.credit_no_commission },
  { index: 13, i18nKey: RootI18nKey + DocumentTypeNameKey.cancel_no_commission },
  { index: 14, i18nKey: RootI18nKey + DocumentTypeNameKey.value_date_change_plus },
  { index: 15, i18nKey: RootI18nKey + DocumentTypeNameKey.rebooking },
  { index: 20, i18nKey: RootI18nKey + DocumentTypeNameKey.debit_no_commission },
];

export default {
  documentTypeList,
};

export function documentTypesWithLocale(backendIndices: any = []) {

  let array = [
    { index: 1, documentType: i18n.tc(`document_type.invoice`) },
    { index: 2, documentType: i18n.tc(`document_type.credit`) },
    { index: 3, documentType: i18n.tc(`document_type.cancel`) },
    { index: 4, documentType: i18n.tc(`document_type.value_date_change_minus`) },
    { index: 5, documentType: i18n.tc(`document_type.transfer`) },
    { index: 6, documentType: i18n.tc(`document_type.minus_carry`) },
    { index: 8, documentType: i18n.tc(`document_type.penalty`) },
    { index: 9, documentType: i18n.tc(`document_type.bonus`) },
    { index: 10, documentType: i18n.tc(`document_type.debit`) },
    { index: 11, documentType: i18n.tc(`document_type.invoice_no_commission`) },
    { index: 12, documentType: i18n.tc(`document_type.credit_no_commission`) },
    { index: 13, documentType: i18n.tc(`document_type.cancel_no_commission`) },
    { index: 14, documentType: i18n.tc(`document_type.value_date_change_plus`) },
    { index: 15, documentType: i18n.tc(`document_type.rebooking`) },
    { index: 20, documentType: i18n.tc(`document_type.debit_no_commission`) },
  ];
  if (backendIndices.length > 0) {
    array = array.filter((x) => backendIndices.includes(x.index));
  }

  return array;
}

// 1	Rechnung
// 2	Gutschrift
// 3	Storno
// 4	Valutaänderung (minus)
// 5	Umbuchung (minus)
// 6	Minusvortrag
// 8	Vertragsstrafe
// 9	Bonus
// 10	Belastung
// 11	Rechnung (provisionsfrei)
// 12	Gutschrift (provisionsfrei)
// 13	Storno (provisionsfrei)
// 14	Valutaänderung (plus)
// 15	Umbuchung (plus)
// 20	Belastung (provisionsfrei)
