import { GetterTree } from 'vuex';
import { ChangeVoucherState } from './types';
import { RootState } from '../../types';
import { ChangeVoucherSearchData } from '@/shared/model/smallPayloadModels/changeVoucherSearchData';

export const getters: GetterTree<ChangeVoucherState, RootState> = {
  ['getChangeVouchers']: (state) => state.changeVouchers,
  ['getChangeVouchersItems']: (state) => state.changeVouchers.items,
  ['getChangeVouchersIsLoading']: (state) => state.changeVouchers?.isLoading,
  ['getChangeVouchersTotal']: (state) => state.changeVouchers?.total,

  ['getChangeVoucher']: (state) => state.changeVoucher,
  ['getChangeVouchersSearchParams']: (state) => state.changeVouchers?.searchParams,
  ['getChangeVoucherSearchData']: (state) => state.changeVoucherSearchData,
  ['getChangeVoucherMinMaxBruttoNetto']: (state) => state.changeVoucherMinMaxBruttoNetto,
  ['getChangeVoucherAeaTyps']: (state) => state.changeVoucherAeaTyps,
  ['getChangeVoucherStatusTypes']: (state) => state.changeVoucherStatusTypes,
  ['getChangeVoucherIntStatusTypes']: (state) => state.changeVoucherIntStatusTypes,
  ['getChangeVoucherMembers']: (state) => state.changeVoucherMembers,
  ['getChangeVoucherSuppliers']: (state) => state.changeVoucherSuppliers,
  ['getInitialLocalStorageChangeVoucherSearchData']: (state) => state.initialLocalStorageChangeVoucherSearchData,

  ['isChangeVoucherSearchDataInitLocalStorageEmpty']: (state) => {
    const localStorageChangeVoucherSearchData = state.initialLocalStorageChangeVoucherSearchData;
    if (!localStorageChangeVoucherSearchData) return true;
    
    // TODO (GSP-046): extract duplicate logic in helper class
    const searchData: ChangeVoucherSearchData = JSON.parse(localStorageChangeVoucherSearchData);
    let hasDate = !!searchData.erfassungsdatum || !!searchData.dueDate;
    let hasAeaTyp = searchData.aeaType.length > 0;
    let hasIntSatusType = searchData.intStatusType?.length > 0;
    let hasDocType = searchData.statusType?.length > 0;
    let hasMembers = searchData.receiverNumbers?.length > 0;
    let hasSupplier = searchData.senderNumbers?.length > 0;
    let hasBrutto = !!searchData.bruttoNetto?.bruttoMin || !!searchData.bruttoNetto?.bruttoMax;
    let hasNetto = !!searchData.bruttoNetto?.nettoMin || !!searchData.bruttoNetto?.nettoMax;

    let isEmpty = !hasDate && !hasSupplier && !hasMembers && !hasAeaTyp && !hasDocType && !hasIntSatusType && !hasBrutto && !hasNetto;
    return isEmpty;
  },

  ['isChangeVoucherSearchDataEmpty']: (state) => {
    const localStorageChangeVoucherSearchData = state.initialLocalStorageChangeVoucherSearchData;
    if (!localStorageChangeVoucherSearchData || !state.changeVoucherSearchData) return true;

    // TODO (GSP-046): extract duplicate logic in helper class
    const searchData: ChangeVoucherSearchData = state.changeVoucherSearchData;
    let hasDate = !!searchData.erfassungsdatum || !!searchData.dueDate;
    let hasAeaTyp = searchData.aeaType?.length > 0;
    let hasDocType = searchData.statusType?.length > 0;
    let hasIntSatusType = searchData.intStatusType?.length > 0;
    let hasMembers = searchData.receiverNumbers?.length > 0;
    let hasSupplier = searchData.senderNumbers?.length > 0;
    let hasBrutto = !!searchData.bruttoNetto?.bruttoMin || !!searchData.bruttoNetto?.bruttoMax;
    let hasNetto = !!searchData.bruttoNetto?.nettoMin || !!searchData.bruttoNetto?.nettoMax;

    let isEmpty = !hasDate && !hasSupplier && !hasMembers && !hasAeaTyp && !hasDocType && !hasIntSatusType && !hasBrutto && !hasNetto;
    return isEmpty;
  },
};
