import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import invSearchData, { InvoiceSearchData } from '@/shared/model/smallPayloadModels/invoiceSearchData';
import { documentTypesWithLocale } from '@/shared/model/smallPayloadModels/documentType';
import DateField from '@/components/_common/date-field/DateField.vue';
import { documentTypeList } from '@/shared/model/smallPayloadModels/documentType';
import SupplierTooltip from '../../zr-documents/search-form/supplier-tooltip/supplier-tooltip.vue';

const logger = new Logger('search-form-invoice');
const invoiceModule = namespace('invoice');
const authModule = namespace('auth');

@Component({
  components: { DateField, SupplierTooltip },
})
export default class SearchForm extends Vue {
  @Ref('documentTypeCombobox')
  private refDocumentTypeCombobox!: any;
  @Ref('membersCombobox')
  private refMembersCombobox!: any;
  @Ref('suppliersCombobox')
  private refSuppliersCombobox!: any;

  @invoiceModule.Action('getInvoiceMinMaxBruttoNetto')
  private actionGetInvoiceMinMaxBruttoNetto!: any;
  @invoiceModule.Getter('getInvoiceMinMaxBruttoNetto')
  private getInvoiceMinMaxBruttoNetto!: any;

  @invoiceModule.Action('getInvoiceDocumentTypes')
  private actionGetInvoiceDocumentTypes!: any;
  @invoiceModule.Getter('getInvoiceDocumentTypes')
  private getInvoiceDocumentTypes!: any;

  @invoiceModule.Action('getZrDocumentsStatuses')
  private actionGetZrDocumentsStatuses!: any;
  @invoiceModule.Getter('getZrDocumentsStatuses')
  private getZrDocumentsStatuses!: any;

  @invoiceModule.Action('getInvoiceMembers')
  private actionGetInvoiceMembers!: any;
  @invoiceModule.Getter('getInvoiceMembers')
  private getInvoiceMembers!: any;

  @invoiceModule.Action('getInvoiceSuppliers')
  private actionGetInvoiceSuppliers!: any;
  @invoiceModule.Action('updateInvoiceSuppliers')
  private actionUpdateInvoiceSuppliers!: any;
  @invoiceModule.Getter('getInvoiceSuppliers')
  private getInvoiceSuppliers!: any;

  @invoiceModule.Action('resetInvoiceSearchFormData')
  private actionResetInvoiceSearchFormData!: any;
  @invoiceModule.Action('updateInvoiceSearchData')
  private actionUpdateInvoiceSearchData!: any;
  @invoiceModule.Getter('getInvoicesSearchParams')
  private invoicesSearchParams!: any;
  @invoiceModule.Action('updateInitialLocalStorageInvoiceSearchData')
  private actionUpdateInitialLocalStorageInvoiceSearchData!: any;
  @invoiceModule.Getter('isInvoiceSearchDataInitLocalStorageEmpty')
  private isInvoiceSearchDataInitLocalStorageEmpty!: any;
  @invoiceModule.Getter('isInvoiceSearchDataEmpty')
  private isInvoiceSearchDataEmpty!: any;

  @invoiceModule.Getter('getInvoiceSearchData')
  private getInvoiceSearchData!: any;

  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroupGetter!: any;
  @authModule.Getter('isUserOnlyGs')
  private isUserOnlyGs!: any;

  @authModule.Getter('zrNummer')
  private zrNummer!: any;
  @authModule.Getter('zrNummerWithRoles')
  private zrNummerWithRoles!: any;
  @authModule.Getter('supplierTooltipInfo')
  private supplierTooltipInfo!: any;

  private isOpenedMenuNettoMinMax: boolean = false;
  private isOpenedMenuBruttoMinMax: boolean = false;

  get isSingleZrNummer() {
    return !!this.zrNummer && !this.zrNummer.includes(',');
  }

  created() {
    // fix strange error `getInvoiceSearchData` is undefined
    console.log('created this.getInvoiceSearchData :>> ', this.getInvoiceSearchData);
    if (!this.getInvoiceSearchData) {
      this.getInvoiceSearchData = invSearchData.defaultData(); // `{}` returned  error
    }
  }

  async mounted() {
    var promiseAll = [];

    if (this.isUserInAzureAdminGroupGetter || (!this.isUserInAzureAdminGroupGetter && !this.isSingleZrNummer)) {
      promiseAll.push(this.getMembers());
    }
    // else if(!!this.zrNummer && this.zrNummer.includes(',')) {
    //   this.members = this.zrNummer.split(',');
    // }

    if (this.isUserInAzureAdminGroupGetter || !(!this.isUserInAzureAdminGroupGetter && this.zrNummer == '-')) {
      promiseAll.push(this.getDocumentTypes(), this.getSuppliers(), this.getMinMaxBruttoNetto());
      await Promise.all(promiseAll);
    }

    const localStorageInvoiceSearchData = localStorage.getItem('invoiceSearchData');
    if (!!localStorageInvoiceSearchData) {
      this.actionUpdateInitialLocalStorageInvoiceSearchData(localStorageInvoiceSearchData);
    }

    //avoid extra query if localStorage is empty on initial load
    if (localStorageInvoiceSearchData && !this.isInvoiceSearchDataInitLocalStorageEmpty) {
      let obj = JSON.parse(localStorageInvoiceSearchData);
      this.invoiceSearchData = Object.assign({}, obj);

      this.$nextTick(() => {
        this.invoiceSearchData = Object.assign({}, obj); // fix strange bug that data not load in inputs in `search-forms` from data
      });
    }
  }

  getZrNummerDependOnRole() {
    let result = this.isUserOnlyGs ? (this.zrNummerWithRoles.invoiceZrNummer + '') || '-' : this.zrNummer + '';
    return result;
  }

  isInvoiceSearchDataWasCleared = false;
  @Watch('isInvoiceSearchDataEmpty', { deep: true })
  public async onIsInvoiceSearchDataEmpty(newVal: boolean, oldVal: boolean) {
    logger.log('isInvoiceSearchDataEmpty isInvoiceSearchDataEmpty :>> ', newVal);
    if (newVal == true) {
      this.isInvoiceSearchDataWasCleared = true; // avoid eternal loop on clearing `invoiceSearchData` (watcher glitch without this variable)
      this.invoiceSearchData = invSearchData.defaultData();

      this.resetBruttoNetto(); // reset `bruttoNetto` when clear all filters
    }
  }

  @Watch('invoiceSearchData', { deep: true })
  public async onOptionsFilterChanged(newVal: InvoiceSearchData, oldVal: InvoiceSearchData) {
    // avoid loop when `invoiceSearchData` was reset
    if (!this.isInvoiceSearchDataWasCleared) {
      // (GSP-102) Always take into account the `zrNummer` to avoid show all records for `gsUser`
      const payload = Object.assign({}, this.invoiceSearchData);
      payload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
      payload.zrNummer =  this.getZrNummerDependOnRole();

      this.actionUpdateInvoiceSearchData(Object.assign({}, payload)); // (GSP-075)  use `Object.assign` to break reference for `invoiceSearchData` to get new data from backend on any input clear or multiple select
    }
    this.isInvoiceSearchDataWasCleared = false;
  }

  private clearFacetFilter: boolean = false;
  @Watch('invoicesSearchParams', { deep: true })
  public async onOptionsFacetFilterChanged(newVal: any, oldVal: any) {
    this.clearFacetFilter = newVal.andClauseFieldsIds.length == 0;
  }

  private documentTypeName(value: number) {
    let i18key = documentTypeList.find((x) => x.index == value)?.i18nKey;
    let formattedResult = i18key ? `${this.$t(i18key)} (${value})` : value;
    return formattedResult;
  }

  private loadingDocumentTypes = false;
  private async getDocumentTypes() {
    if (this.getInvoiceDocumentTypes?.length > 0) {
      this.documentTypes = this.getInvoiceDocumentTypes;
    } else {
      this.loadingDocumentTypes = true;
      let payload = this.setInvoiceSearchFormFiltersPayload();
      await this.actionGetInvoiceDocumentTypes(payload)
        .then((result: any) => {
          this.documentTypes = this.getInvoiceDocumentTypes;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingDocumentTypes = false;
        });
    }
  }

  private loadingMembers = false;
  private async getMembers() {
    if (this.getInvoiceMembers?.length > 0) {
      this.members = this.getInvoiceMembers;
    } else {
      this.loadingMembers = true;
      let payload = this.setInvoiceSearchFormFiltersPayload();
      await this.actionGetInvoiceMembers(payload)
        .then((result: any) => {
          this.members = result;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingMembers = false;
        });
    }
  }

  private loadingSuppliers = false;
  private async getSuppliers() {
    if (this.getInvoiceSuppliers?.length > 0) {
      this.suppliers = this.getInvoiceSuppliers;
    } else {
      this.loadingSuppliers = true;
      let payload = this.setInvoiceSearchFormFiltersPayload();
      await this.actionGetInvoiceSuppliers(payload)
        .then((result: any) => {
          if (this.supplierTooltipInfo) {
            // ONLY_DEV (GSP-117) for now loading tooltip only for dev
            this.suppliers = result.map((item: any) =>
              Object.assign(
                item,
                this.supplierTooltipInfo.find((y: any) => y.zrNummer == item.id)
              )
            );

            this.actionUpdateInvoiceSuppliers(this.suppliers);
          } else {
            this.suppliers = result;
          }

        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingSuppliers = false;
        });
    }
  }

  invoiceSearchData = invSearchData.defaultData();

  documentTypes: any = [];
  members = [];
  suppliers = [];

  //#region BruttoNetto logic
  bruttoNetto: any = [];
  bruttoNettoMinMax: any = [];

  
  @Watch('$i18n.locale')
  private onLocaleChanged() {
   let backendIndices = this.getInvoiceDocumentTypes.map((x: any) => x.index);  
   this.documentTypes = documentTypesWithLocale(backendIndices);   
  }

  private loadingMinMaxBruttoNetto = false;
  private async getMinMaxBruttoNetto() {
    console.log('object getInvoiceSearchData:>> ', this.getInvoiceSearchData);
    // if (this.getMinMaxBruttoNetto?.length > 0) {
    // } else {
    this.loadingMinMaxBruttoNetto = true;
    let payload = this.setInvoiceSearchFormFiltersPayload();
    await this.actionGetInvoiceMinMaxBruttoNetto(payload)
      .then((result: any) => {
        // `getInvoiceMinMaxBruttoNetto` also formatted
        // (GSP-145) avoid error if no results in database
        if (result?.bruttoMax || result?.nettoMax ) {
          Object.keys(result).forEach((key, index) => {
            result[key] = result[key].toLocaleString(this.$i18n.locale);
          });
        }

        this.bruttoNettoMinMax = Object.assign({}, result);
        this.bruttoNetto = Object.assign({}, result);
      })
      .catch((err: any) => {
        logger.error(err);
      })
      .finally(() => {
        this.loadingMinMaxBruttoNetto = false;
      });
    // }
  }

  get bruttoMin() {
    return this.invoiceSearchData.bruttoNetto?.bruttoMin
      ? (+this.invoiceSearchData.bruttoNetto?.bruttoMin)?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : this.isInvoiceSearchDataEmpty
      ? this.bruttoNettoMinMax?.bruttoMin?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : this.bruttoNetto?.bruttoMin?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
  }
  get bruttoMax() {
    return this.invoiceSearchData.bruttoNetto?.bruttoMax
      ? (+this.invoiceSearchData.bruttoNetto?.bruttoMax)?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : this.isInvoiceSearchDataEmpty
      ? this.bruttoNettoMinMax.bruttoMax?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : this.bruttoNetto.bruttoMax?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
  }
  get nettoMin() {
    return this.invoiceSearchData.bruttoNetto?.nettoMin
      ? (+this.invoiceSearchData.bruttoNetto?.nettoMin)?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : this.isInvoiceSearchDataEmpty
      ? this.bruttoNettoMinMax.nettoMin?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : this.bruttoNetto.nettoMin?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
  }
  get nettoMax() {
    return this.invoiceSearchData.bruttoNetto?.nettoMax
      ? (+this.invoiceSearchData.bruttoNetto?.nettoMax)?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : this.isInvoiceSearchDataEmpty
      ? this.bruttoNettoMinMax.nettoMax?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      : this.bruttoNetto.nettoMax?.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
  }

  get bruttoOneText() {
    let result = '';
    if (this.getInvoiceSearchData.bruttoNetto?.bruttoMin && this.getInvoiceSearchData.bruttoNetto?.bruttoMax) {
      result = `${(+this.getInvoiceSearchData.bruttoNetto?.bruttoMin)?.toLocaleString(this.$i18n.locale)} ${this.$t(
        'to'
      )} ${(+this.getInvoiceSearchData.bruttoNetto?.bruttoMax)?.toLocaleString(this.$i18n.locale)}`;
    }

    if (this.getInvoiceSearchData.bruttoNetto?.bruttoMin && !this.getInvoiceSearchData.bruttoNetto?.bruttoMax) {
      result = `${(+this.getInvoiceSearchData.bruttoNetto?.bruttoMin)?.toLocaleString(this.$i18n.locale)} ${this.$t(
        'to'
      )} ${this.bruttoNettoMinMax.bruttoMax}`;
    }

    if (!this.getInvoiceSearchData.bruttoNetto?.bruttoMin && this.getInvoiceSearchData.bruttoNetto?.bruttoMax) {
      result = `${this.bruttoNettoMinMax.bruttoMin} ${this.$t('to')} ${(+this.getInvoiceSearchData.bruttoNetto
        ?.bruttoMax)?.toLocaleString(this.$i18n.locale)}`;
    }

    return result;
  }

  get nettoOneText() {
    let result = '';
    if (this.getInvoiceSearchData.bruttoNetto?.nettoMin && this.getInvoiceSearchData.bruttoNetto?.nettoMax) {
      result = `${(+this.getInvoiceSearchData.bruttoNetto?.nettoMin)?.toLocaleString(this.$i18n.locale)} ${this.$t(
        'to'
      )} ${(+this.getInvoiceSearchData.bruttoNetto?.nettoMax)?.toLocaleString(this.$i18n.locale)}`;
    }

    if (this.getInvoiceSearchData.bruttoNetto?.nettoMin && !this.getInvoiceSearchData.bruttoNetto?.nettoMax) {
      result = `${(+this.getInvoiceSearchData.bruttoNetto?.nettoMin)?.toLocaleString(this.$i18n.locale)} ${this.$t(
        'to'
      )} ${this.bruttoNettoMinMax.nettoMax}`;
    }

    if (!this.getInvoiceSearchData.bruttoNetto?.nettoMin && this.getInvoiceSearchData.bruttoNetto?.nettoMax) {
      result = `${this.bruttoNettoMinMax.nettoMin} ${this.$t('to')} ${(+this.getInvoiceSearchData.bruttoNetto
        ?.nettoMax)?.toLocaleString(this.$i18n.locale)}`;
      }

      return result;
  }

  resetBruttoNetto() {
    this.bruttoNetto = Object.assign({}, this.bruttoNettoMinMax);
  }

  clearNetto() {
    this.invoiceSearchData.bruttoNetto.nettoMin = null;
    this.invoiceSearchData.bruttoNetto.nettoMax = null;
    this.invoiceSearchData.triggerChange = !this.invoiceSearchData.triggerChange;
    this.invoiceSearchData = Object.assign({}, this.invoiceSearchData);
  }
  clearBrutto() {
    this.invoiceSearchData.bruttoNetto.bruttoMin = null;
    this.invoiceSearchData.bruttoNetto.bruttoMax = null;
    this.invoiceSearchData.triggerChange = !this.invoiceSearchData.triggerChange;
    this.invoiceSearchData = Object.assign({}, this.invoiceSearchData);
  }

  formatInput(value: any) {
    if (this.$i18n.locale == 'de') {
      value = value.replaceAll('.', '').replace(',', '.').trim();
    }
    return value;
  }

  checkBruttoMin(e: any) {
    let value = this.formatInput(e.target.value);
    this.bruttoNetto.bruttoMin = (+value).toLocaleString(this.$i18n.locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    this.checkWithDbMinMaxAndUpdate('bruttoMin');
  }

  checkBruttoMax(e: any) {
    let value = this.formatInput(e.target.value);
    this.bruttoNetto.bruttoMax = (+value).toLocaleString(this.$i18n.locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    this.checkWithDbMinMaxAndUpdate('bruttoMax');
  }

  checkNettoMin(e: any) {
    let value = this.formatInput(e.target.value);
    this.bruttoNetto.nettoMin = (+value).toLocaleString(this.$i18n.locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    this.checkWithDbMinMaxAndUpdate('nettoMin');
  }

  checkNettoMax(e: any) {
    let value = this.formatInput(e.target.value);
    this.bruttoNetto.nettoMax = (+value).toLocaleString(this.$i18n.locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    this.checkWithDbMinMaxAndUpdate('nettoMax');
  }

  checkWithDbMinMaxAndUpdate(control: string) {
    if (this.getInvoiceMinMaxBruttoNetto?.bruttoMin != this.bruttoNetto.bruttoMin || control == 'bruttoMin') {
      this.invoiceSearchData.bruttoNetto.bruttoMin = this.bruttoNetto.bruttoMin;
    }
    if (this.getInvoiceMinMaxBruttoNetto?.bruttoMax != this.bruttoNetto.bruttoMax || control == 'bruttoMax') {
      this.invoiceSearchData.bruttoNetto.bruttoMax = this.bruttoNetto.bruttoMax;
    }
      if (this.getInvoiceMinMaxBruttoNetto?.nettoMin != this.bruttoNetto.nettoMin || control == 'nettoMin') {
          this.invoiceSearchData.bruttoNetto.nettoMin = this.bruttoNetto.nettoMin;
    }
    if (this.getInvoiceMinMaxBruttoNetto?.nettoMax != this.bruttoNetto.nettoMax || control == 'nettoMax') {
      this.invoiceSearchData.bruttoNetto.nettoMax = this.bruttoNetto.nettoMax;
    }

    if (this.$i18n.locale == 'de') {
      Object.keys(this.invoiceSearchData.bruttoNetto).forEach((key, index) => {
        if (this.invoiceSearchData.bruttoNetto[key] != null) {
          this.invoiceSearchData.bruttoNetto[key] = this.invoiceSearchData.bruttoNetto[key]
            .replaceAll('.', '')
            .replace(',', '.');
        }
      });
    }

    this.invoiceSearchData.triggerChange = !this.invoiceSearchData.triggerChange; // (GSP-116) need this trick to send request to backend, since data is cached somehow by reference, and comparing new/old `invoiceSearchData` for `bruttoNetto` in watcher return equal objects (`areEqual` == true) and NOT send request to backend to avoid duplicate requests
    this.invoiceSearchData = Object.assign({}, this.invoiceSearchData);
  }
  //#endregion

  searchInvoices() {
    var data = Object.assign({}, this.invoiceSearchData); // avoid sending requests when change invoiceSearchData (watcher in `my-selection-table` component)
    this.actionUpdateInvoiceSearchData(data);
  }

  changeDate(value: any) {
    this.invoiceSearchData.datum = value || undefined; // new Date(value).toJSON();
    this.triggerSearchDataUpdate();
  }
  changeDueDate(value: any) {
    this.invoiceSearchData.dueDate = value || undefined; // new Date(value).toJSON();
    this.triggerSearchDataUpdate();
  }
  changeEndOfWeekDate(value: any) {
    this.invoiceSearchData.endOfWeekDate = value || undefined; // new Date(value).toJSON();
    this.triggerSearchDataUpdate();
  }

  triggerSearchDataUpdate() {
    // (GSP-102) Always take into account the `zrNummer` to avoid show all records for `gsUser`
    const payload = Object.assign({}, this.invoiceSearchData);
    payload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
    payload.zrNummer = this.getZrNummerDependOnRole();

    this.invoiceSearchData = Object.assign({}, payload); // workaround (sometimes data change is just ignored by watcher)
  }

  //#region Filtering autocomplete data based on previous autocomplete values
  private setInvoiceSearchFormFiltersPayload() {
    let newVal = this.invoiceSearchData;
    let chipDataString= this.invoicesSearchParams.andClauseFieldsIds.map((el: any) => `${el.chipField}:${el.searchWord}`).join(',');
    let payload: InvoiceSearchData = {
      documentType: newVal.documentType,
      receiverNumbers: newVal.receiverNumbers, //mitglied,
      senderNumbers: newVal.senderNumbers, // lieferant,
      datum: newVal.datum,
      dueDate: newVal.dueDate,
      endOfWeekDate: newVal.endOfWeekDate,
      isUserInAzureAdminGroup: this.isUserInAzureAdminGroupGetter,
      zrNummer: this.getZrNummerDependOnRole(),
      chipDataString: chipDataString
    };
    return payload;
  }

  showMenu() {
    this.refDocumentTypeCombobox.activateMenu();
  }

  reloadDocumentTypesList: boolean = false;
  reloadMembersList: boolean = false;
  reloadSuppliersList: boolean = false;

  filterDocumentTypes() {
    if ((this.invoiceSearchData.documentType?.length > 0 ||
      this.invoiceSearchData.receiverNumbers?.length > 0 ||
      this.invoiceSearchData.senderNumbers?.length > 0)
      || this.reloadDocumentTypesList
      || this.invoicesSearchParams.andClauseFieldsIds.length > 0
      || this.clearFacetFilter
    ) {
      this.loadingDocumentTypes = true;
      let payload = this.setInvoiceSearchFormFiltersPayload();
      this.reloadDocumentTypesList = !this.reloadDocumentTypesList;

      this.actionGetInvoiceDocumentTypes(payload)
        .then((result: any) => {
          console.log('result :>> ', result);
          this.documentTypes = this.getInvoiceDocumentTypes;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingDocumentTypes = false;
          console.log('DocumentTypesCombobox :>> ', this.refDocumentTypeCombobox);
          // this.refDocumentTypeCombobox.activateMenu();

          this.$nextTick(() => {
            this.refDocumentTypeCombobox.activateMenu();
          });
        });
    }
  }

  filterMembers() {
    if ((this.invoiceSearchData.documentType?.length > 0 ||
      this.invoiceSearchData.receiverNumbers?.length > 0)
      || this.reloadMembersList
      || this.invoicesSearchParams.andClauseFieldsIds.length > 0
      || this.clearFacetFilter
    )
      {
      this.loadingMembers = true;
      let payload = this.setInvoiceSearchFormFiltersPayload();
      this.reloadMembersList = !this.reloadMembersList;

      this.actionGetInvoiceMembers(payload)
        .then((result: any) => {
          this.members = result;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingMembers = false;

          this.$nextTick(() => {
            this.refMembersCombobox.activateMenu();
          });
        });
    }
  }

  filterSupplier() {
    if (
      (this.invoiceSearchData.documentType?.length > 0 ||
        this.invoiceSearchData.receiverNumbers?.length > 0 ||
        this.invoiceSearchData.senderNumbers?.length > 0)
        || this.reloadSuppliersList
      || this.invoicesSearchParams.andClauseFieldsIds.length > 0
      || this.clearFacetFilter
    ) {
      this.loadingSuppliers = true;
      let payload = this.setInvoiceSearchFormFiltersPayload();
      this.reloadSuppliersList = !this.reloadSuppliersList;
      this.actionGetInvoiceSuppliers(payload)
        .then((result: any) => {
          // this.suppliers = result;
          if (this.supplierTooltipInfo) {
            // ONLY_DEV (GSP-117) for now loading tooltip only for dev
            this.suppliers = result.map((item: any) =>
              Object.assign(
                item,
                this.supplierTooltipInfo.find((y: any) => y.zrNummer == item.id)
              )
            );
          } else {
            this.suppliers = result;
          }

        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingSuppliers = false;

          this.$nextTick(() => {
            this.refSuppliersCombobox.activateMenu();
          });
        });
    }
  }
  //#endregion

  searchDocumentTypeInput = '';
  searchMembersInput = '';
  searchSupplierInput = '';

  searchModelNameInput = '';
  searchStatusInput = '';

  clearSearchInput() {
    this.searchDocumentTypeInput = '';
    this.searchStatusInput = '';
    this.searchMembersInput = '';
    this.searchModelNameInput = '';
    this.searchSupplierInput = '';
  }

  clearInvoiceSearchFormData() {
    this.actionResetInvoiceSearchFormData();
  }

  get isInvoiceSearchFormHasData() {
    return (
      this.invoiceSearchData.documentType.length > 0 ||
      this.invoiceSearchData.receiverNumbers.length > 0 ||
      this.invoiceSearchData.senderNumbers.length > 0 ||
      // this.invoiceSearchData.status.length > 0 ||
      this.invoiceSearchData.datum?.length > 0 ||
      !!this.invoiceSearchData.datum
    );
  }

  bruttoMinEnter() {
    (this.$refs['bruttoMaxValue'] as any).focus();
  }
  bruttoMaxEnter() {
    if (this.isOpenedMenuBruttoMinMax == true) {
      this.isOpenedMenuBruttoMinMax = false;
    }
  }

  nettoMinEnter() {
    (this.$refs['nettoMaxValue'] as any).focus();
  }

  nettoMaxEnter() {
    if (this.isOpenedMenuNettoMinMax == true) {
      this.isOpenedMenuNettoMinMax = false;
    }
  }
  onMenuNettoMinMax(opened: any) {
    if (opened == true) {
      this.isOpenedMenuNettoMinMax = true;
    }
    else {
      this.isOpenedMenuNettoMinMax = false;
    }
  }

  onMenuBruttoMinMax(opened: any) {
    if (opened == true) {
      this.isOpenedMenuBruttoMinMax = true;
    }
    else {
      this.isOpenedMenuBruttoMinMax = false;
    }
  }

  nettoTextDown() {
    let inputMinValue = (this.$refs['nettoInvMinValue'] as any).$el.querySelector('input');
    setTimeout(() => {
      inputMinValue.select()
    }, 50);
    (this.$refs['nettoInvMinValue'] as any).focus();
  }

  bruttoTextDown() {
    let inputMinValue = (this.$refs['bruttoInvMinValue'] as any).$el.querySelector('input');
    setTimeout(() => {
      inputMinValue.select()
    }, 50);
    (this.$refs['bruttoInvMinValue'] as any).focus();
  }

  nettoTextClick() {
    setTimeout(() => {
      (this.$refs['nettoInvMinValue'] as any).focus();
      let inputMinValue = (this.$refs['nettoInvMinValue'] as any).$el.querySelector('input');
      inputMinValue.select()
    }, 200);
  }

  bruttoTextClick() {

    setTimeout(() => {
      (this.$refs['bruttoInvMinValue'] as any).focus();
      let inputMinValue = (this.$refs['bruttoInvMinValue'] as any).$el.querySelector('input');
      inputMinValue.select()
    }, 200);
  }

}